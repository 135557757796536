.aboutus_cq {
  direction: rtl;
}

.aboutus_cq_title {
  font-size: 18px;
  color: #212121;
  line-height: 36px;
  font-weight: 700;
}

.aboutus_cq_content {
  font-size: 16px;
  line-height: 17px;
  font-weight: 400;
  margin-bottom: 4px;
}