.blends-input {
  position: relative;
}
.blends-input-wrapper.error .blends-input {
  border-color: #FF1F84;
}
.blends-input {
  border: 0.5px solid #767676;
  box-shadow: inset 1px 0px 5px 1px rgba(204, 204, 204, 0.25);
  border-radius: 5px;
  height: 40px;
  padding: 5px 20px;
  width: 100%;
  outline: none;
}

.blends-input-label {
  font-size: 16px;
  line-height: 36px;
  font-weight: 600;
  margin-bottom: 0;
}