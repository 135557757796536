textarea {
  resize: none;
  width: 100%;
  border: 0.5px solid #cccccc;
  box-shadow: 0px 0px 3px rgba(204, 204, 204, 0.25);
  border-radius: 5px;
  /* min-height: 100px; */
  padding: 11px 18px;
}

textarea::placeholder {
    color: #C8C8C8;
    font-size: 14px;
    font-weight: 400;
}