/* ProductListing.css */
.product-listing {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 89%; /* Set the width of the component to 88% of the screen width */
  margin: 0 auto; /* This will center the component horizontally */
  max-width: 100vw; /* Adding a max-width constraint */
  margin-top: 26px;
  background-color: #FFFDFB;
}

.image-container {
  position: relative;
  width: 88vw;
}

.image-container img {
  width: 88vw;
  max-height: 440px;
  height: auto;
  border-radius: 12px;
  object-fit: cover;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.change-shape-btn {
  position: absolute;
  bottom: 30%; 
  left: 50%; 
  transform: translate(-50%, 50%);
  border-radius: 8px;
  border: none;
  background-color: white;
  height: 50px;
  width: 160px;
  color: #087E63;
  font-weight: 600;
  font-size: 17.3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16px; 
  padding-right: 20px; 
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;


  svg {
    width: 46px;
    margin-right: auto;
    margin-bottom: 8px;
  }
}

.spin-star {
  animation: spinStar 1s linear infinite;
  transform-origin: 70px 55px;
}

.product-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 98%;
  margin-top: 12px;
  margin-bottom: 16px;
}

.product-text {
  display: flex;
  flex-direction: column;
  margin-right: auto;
  text-align: left;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.name-and-price {
display: flex;
align-items: center;
}

.discount-and-tag {
  display: flex;
  align-items: center;
  gap: 8px;
}

.discount-tag {
  padding: 2px 10px;
  font-size: 12px;
  background-color: #FFDFB9;
  border-radius: 8px;
  color: #A4193D;
  font-weight: 500;
}

.checkicon svg{
    margin-left: 1px;
    margin-bottom: 2px;
    scale: 1;
}

.product-name,
.product-price {
  margin: 0;
  padding-right: 8px; 
}

.product-price {
  text-decoration: line-through; 
  color: #5C5C5C;
}

.discounted-price {
  color: #087E63;
}

.dimension-btn {
  height: 45px; 
  width: 56px;
  border-radius: 8px;
  border: none;
  align-items: center;
  background-color: #087E63;
  transition: transform 0.3s ease;
  
  .dim-x img {
    width: 40px;
  }
  
  img {
    width: 30px;
  }

}

.dimension-btn-scale-down {
  transform: scale(0.98);
}

.dimension-btn-scale-up {
  transform: scale(1.02);
}


.upload-link {
  display: flex;
  align-items: start;
  direction: rtl;
  width: 100%;
}

.upload-link a {
  border-bottom: 1px solid #000; 
  line-height: 20px;
  margin-bottom: 16px;
  color: #000; 
  font-weight: 700;
}

.upload-link a:after {
  content: ' >';
  margin-right: 5px;
}

@keyframes scaleShapeButton {
  0%, 100% { transform: translate(-50%, 50%) scale(1); }
  50% { transform: translate(-50%, 50%) scale(0.99); }
}

@keyframes spinStar {
  0% { transform: scale(1); }
  100% { transform: scale(1); }
}