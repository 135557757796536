.footer-mob-wrap-bg {
    background-color: #F3F6F4;
    padding-top: 12px;
    margin-top: 20px;
}
.footer-mob {
    font-family: 'Rubik', sans-serif;
    max-width: 88vw;
    margin: auto;
    background-color: #F3F6F4;
}

/* Q&A Section */
.qa-section .qa-row {
  border-bottom: 1px solid #ccc;
  padding: 10px 0;
}

.qa-section .qa-row .question {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.qa-row .question {
    justify-content: space-between;
    direction: rtl;
    width: 88vw;
    color: #657571;
}

.qa-section .qa-row .icon {
  margin-right: 10px;
  font-weight: bold;
  color: #657571;
}

.qa-section .qa-row .answer {
  padding: 10px;
  display: none; /* Initially hidden */
  direction: rtl;
}

.qa-section .qa-row.open .answer {
  display: block; /* Shown when row is open */
}

/* Last row in Q&A section with different icon */
.qa-section .qa-row:last-child .icon {
  transform: rotate(-90deg); /* Rotate the '<' icon */
}
  
.qa-row:last-child {
font-weight: 600;  
}
  /* Titles and Links Section */
  .titles-links-section {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  .social-networks, .site-policies {
    width: 88vw;
    max-width: 88vw;
    display: flex;
    flex-direction: column;
    direction: rtl;
    
    h3 {
        margin-bottom: 12px;
        font-size: 17.3px;
        font-weight: 700;
        color: #657571;
    }
  }
  
  .social-networks a, .site-policies a {
    text-decoration: none;
    color: #657571; /* Change as needed */
    margin-bottom: 5px;
  }
  
  .social-networks a img {
    margin-left: 10px; /* Space between text and image */
    height: auto;
    width: 20px;
  }
  
  /* Payment Options Image */
  .payment-options {
    text-align: center;
    margin-top: 20px;
  }
  
  .payment-options img {
    max-width: 100%;
    width: 80px;
    height: auto;
    margin-bottom: 16px;
  }
  
  /* Copyright Section */
  .copyright-section {
    text-align: center;
    margin-top: 20px;
    color: #657571;
  }
  