.content {
    position: relative; /* Corrected from 'display: relative;' */
    clear: both;
    width: 88vw;
    padding: 0 0 30px 0;
    direction: rtl;
    border-radius: 11px;
    margin: 0 auto !important;
    z-index: 0;
}

.content-video {
    width: 100%;
    display: block;
    margin: auto; /* Centers the video horizontally */
    border-radius: 11px;
    margin-top: 80px;
}

/* Add this for the overlay image */
.content img {
    position: absolute;
    bottom: 80px; /* 40px from the bottom of the video */
    left: 50%; /* Centers the image */
    transform: translateX(-50%); /* Adjusts for the left offset to truly center the image */
    z-index: 1; /* Ensures the image is above the video */
    width: 80%;
}
