.whatsapp {
    width: 520px;
    height: 380px;
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 7px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    direction: rtl;
}

.whatsapp_header {
    text-align: center;
    line-height: 43px;
    font-size: 36px;
    padding-top: 46px;
    font-weight: 400;
}

.whatsapp_content {
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 440px;
    padding-top: 28px;
    padding-right: 46px;
    line-height: 19px;
}

.whatsapp_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 46px;
}

.whatsapp_footer .whatsapp_link {
    display: inline-block;
    background: #FFFFFF;
    box-shadow: 0px 2px 5px rgba(204, 204, 204, 0.5);
    border-radius: 8px;
    width: 209px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #212121;
    font-size: 16px;
}

.whatsapp_footer .whatsapp_link img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.qa-whatsapp-desktop {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 1em;
}