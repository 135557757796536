.video-container {
    position: relative;
    width: 100%;
    max-width: 640px; /* Adjust as necessary */
    margin: auto;
  }
  
  .video-player {
    width: 88vw;
    height: auto;
  }
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 88vw;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.3s ease;
    border-radius: 11px;
    
    img {
        width: 80%;
    }
  }
  
  .video-overlay.hide {
    display: none;
  }
  
  .play-button {
    position: absolute;
    top: 92%;
    left: 10%;
    height: 40px;
    width: 30px;
    border: none;
    background-color: #cccccc90;
    color: white;
    cursor: pointer;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s ease;
  }

    
.play-button img {
        width: auto;
        height: 16px;
    }