@media screen and (min-width: 1200px) {
    .footer-row {
        padding-top: 50px;
    }
}

.footer-logo {
    display: none;
}

.footer-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.footer-column {
    margin-left: auto;
    order: 1;
    padding: 0 38px;
}

.footer-column img {
    padding-top: 6px;
    height: 36px;
    margin: auto;
}

.footer-column p {
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
}

@media screen and (max-width: 767px) {
    .footer-column p {
        display: none;
    }
}

.desktop-footer-column {
    margin-right: 50px;
    display: flex;
    justify-content: space-between;
}

.footer-link {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
}

.footer-links-title-desktop{
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
}
.footer-links-title-mobile {
    color: #000000;
    font-size: 26px;
    font-weight: 700;
    line-height: 28px;
    text-align: right;
    display: flex;
    justify-content: center;
    padding-bottom: 12px;
}

.as-seen-on-mobile p {
    display: flex;
    justify-content: center; 
    margin-top: 16px;
}

.as-seen-on-mobile img {
    width: auto;
}

.mobile-payment-footer {
    display: flex;
    justify-content: center;
    padding-bottom: 12px;
    margin-right: 24px;
}

@media screen and (max-width: 767px) {
    .footer-row {
        display: none;
    }
    .footer-link {
        color: #304036;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }

    .footer-links-title {
        font-size: 14.4px;
        font-weight: 700;
        line-height: 24px;
        text-align: right;
        color: #304036;
    }
}
@media screen and (max-width: 992px) {
    .footer-row {
        grid-template-columns: 1fr 1fr;
        gap: 30px 0;
    }

    .footer-row .footer-column:last-child {
        grid-column-start: 2;
    }
}
@media(max-width:767px){
    .foo-menu-wrap h6{
        display: none;
    }
}
@media screen and (max-width: 767px) {
    .site-footer {
        display: none;
    }
    .site-footer::before {
        display: none;
    }
    .footer-logo {
        display: none;
    }
    .footer-column .ul img {
    }
    .footer-row {
        grid-template-columns: 1fr;
        gap: 15px;
    }
    .footer-row .footer-column:nth-child(2) {
        display: none;
        align-items: flex-end;
    }
    .footer-row .footer-column h6 {
        display: none;
    }
    .footer-row .footer-column:last-child {
        grid-column-start: 1;
    }
    .footer-row .footer-column:nth-child(3) {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 360px;
    }
    .mobile-stick-bottom {
        padding: 15px;
        /* height: 80px;  */
        height: 80px; /*for quick shipping notice*/
        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
    }
    .mobile-stick-bottom a {
        filter: drop-shadow(0px 5px 9px rgba(31, 255, 102, 0.111));
    }
}


.footer-additional-links-container {
    padding: 0 38px;
    text-align: right;
    background-color: white;
    width: 100%;
}

.footer-btn {
    border-radius: 8px;
    padding: 10px 40px;
    background: #087E63;
    color: #fff;
    font-weight: 900 !important;
    font-size: 18px !important;
    width: 70vw;
    height: 50px;
    line-height: 30px;
    display: block;
    margin: auto;
    font-family: Rubik;
    filter: drop-shadow(0px 3px 4px rgba(194, 194, 194, 0.66)) !important;
    outline: none;
}



.footer-btn:hover {
    text-decoration: none;
    color: #fff;
}

.as-seen-on-mobile img {
    width: 90%;
}