.bitbody {
    direction: rtl;
    background: #FAFFFE;
    box-shadow: 0px -4px 15px -5px rgba(233, 233, 233, 0.25);
    width: 490px !important;
    height: 620px;
    border-radius: 10px;
    font-family: 'Rubik';
    color: white;
}

.bitclose {
    margin-right: 20px;
    margin-top: 20px;
    width: 7.18px;
    height: 8.677px;
    flex-shrink: 0; 
    color: #31AFB6;
    cursor: pointer;
}

.bitheader {
    justify-content: center;
    text-align: center;
    margin-top: 24px;
    font-size: 18px;
}

.titletext {
    font-weight: 700;
}

.secondtitletext {
    font-weight: 300;
}

.bitheader img {
    width: 104px;
    margin-top: 16px;
    margin-bottom: 16px;
}

.bitmiddle {
    justify-content: center;
    text-align: center;
    margin-left: 15%;
    margin-right: 15%; 
    font-weight: 300;
}

.bitmiddlenotice {
    margin-top: 30px;
    font-size: 18px;
}

.bitamounttitle {
    margin-top: 16px;
    font-weight: 400;
    margin-bottom: -6px;
    font-size: 18px;
}

.bitamount {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 30px;   
}

.bitbottomarea {
    justify-content: center;
    text-align: center;
}

.bitrefresh {
    position: absolute;
    bottom: 4%;
    left: 50%;
    transform: translateX(-50%);
    color: #000;
    font-size: 16px;
    font-weight: 300;
}

.bitwait {
    margin-top: 24px;
    font-size: 18px;
    font-weight: 300;
}

.bittimer {
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
}