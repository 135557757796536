.upload-options {
  background-color: #fff;
  box-shadow: 0px 0px 15px 10px rgba(204, 204, 204, 0.5);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  position: absolute;
  /* max-width: 335px; */
  width: 94.66%;
  bottom: 15px;
  transition: all .3s ease-in-out;
}
/* .upload-options.open {
    bottom: 15px;
} */

@media screen and (min-width: 768px) {
  .upload-options {
    max-width: 335px;
  }
}

.upload-options__item {
    border: none;
    outline: none!important;
    padding: 20px 5px;
    font-size: 15px;
    line-height: 17px;
    background-color: transparent;
    text-align: right;
    position: relative;
}
.upload-options__item:nth-child(2) {
    border-top: 1px solid #F8F8F8;
    border-bottom: 1px solid #F8F8F8;
}
.upload-options__item img {
    margin-left: 18px;
}