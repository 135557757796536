.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

.desk-up {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFFDFB;
}
.form-upload {
    position: relative;
    z-index: 3;
}

.form-area-upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}


@media only screen and (max-width: 767px) {
.form-area-upload h2 {
    color: #606060;
    font-size: 24px;
    font-weight: 600;
    direction: rtl;
    margin-bottom: 20px;
    animation: fadeIn 2s;
    margin-top: 60%;
}

.form-area-upload {
    margin-top: 50%;
}

.form-area-upload button {
    margin-top: 26px;
    outline: none !important;
    border: none;
    border-radius: 100%;
    background: #ffffff;
    width: 160px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: shadowWave 2s infinite;
    margin-bottom: 126px;
    
    img {
        height: 75px;
    }
  }
  
  .form-area-upload p {
    font-size: 15px;
    color: #606060;
    font-weight: 400;
    padding-bottom: 20px;
    animation: fadeIn 2s;
    
    }
}

@media only screen and (min-width: 768px) {
  .form-area-upload {
    margin-top: 50%;
  }
  
  .form-area-upload button {
        outline: none !important;
        border: none;
        border-radius: 100%;
        background: #ffffff;
        box-shadow: 0 10px 20px rgba(99, 76, 250, 0.1);
        width: 201px;
        height: 201px;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: shadowWave 2s infinite;
        margin-bottom: 126px;
      }
      
  .form-area-upload h2 {
      color: #606060;
      font-size: 24px;
      font-weight: 500;
      direction: rtl;
      margin-top: 30px;
      margin-bottom: 16px;
      animation: fadeIn 2s;
  }
  }
.form-area-upload p {
    font-size: 15px;
    color: #606060;
    font-weight: 400;
    padding-bottom: 20px;
    animation: fadeIn 2s;
}
.form-area-upload button img {
  width: 85px;
  animation-delay: 4s;
  animation: fadeIn 2s;
}

.under-loader-text {
    font-family: Rubik;
    font-size: 14px;
    color: #212121;
    direction: rtl;
}
 

@media screen and (max-width: 767px) {
    .form-upload {
        top: -14%;
    }
}

@keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  
@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes form-pop {
    0%   {transform: scale(.4);}
    80%   {transform: scale(1.1);}
    100% {transform: scale(1);}
  }
  
  @keyframes shadowWave {
    0%, 100% {
      box-shadow: 10px 0 20px rgba(75, 0, 130, 0.1); /* Red */
    }
    14% {
      box-shadow: 7px 7px 31px rgba(0, 0, 255, 0.1); /* Orange */
    }
    28% {
      box-shadow: 0 10px 36px rgba(75, 0, 130, 0.1); /* Yellow */
    }
    42% {
      box-shadow: -7px 7px 31px rgba(0, 0, 255, 0.1); /* Green */
    }
    57% {
      box-shadow: -10px 0 20px rgba(0, 0, 255, 0.1); /* Blue */
    }
    71% {
      box-shadow: -7px -7px 31px rgba(0, 0, 255, 0.1); /* Indigo */
    }
    85% {
      box-shadow: 0 -10px 36px rgba(75, 0, 130, 0.1); /* Violet */
    }
  }
  
  
  @keyframes moveUp {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
