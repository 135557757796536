.cart-error-message {
  position: absolute;
  left: auto;
  right: auto;
  bottom: 46px;
}

.cart-error-message-mobile {
  position: absolute;
  left: auto;
  right: auto;
  bottom: 479px;
  max-width: 90%;
  margin: 0 auto;
}

.cart-error-message-mobile img {
  max-width: 100%;
  height: auto;
}