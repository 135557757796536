.blends-select {
}
.select-wrapper {
  width: 100%;
}
.select-wrapper.error .blends-select__control {
  border-color: #FF9494!important;
}
form .blends-select__control {
  background: #f2f2f2;
  border: 0.5px solid #767676;
  box-shadow: 0px 3px 5px 2px rgba(204, 204, 204, 0.25);
  border-radius: 8px;
  border-color: #767676;
  font-size: 15px;
  margin-top: 2px;
}

form .blends-select__placeholder {
  margin-right: 12px;
}

form .blends-select__indicator-separator {
  display: none;
}

form .blends-select__indicator path {
  fill: #000;
}

form .blends-select__single-value {
  text-align: right;
  padding-right: 5px;
}
form .blends-select__input-container {
  padding-right: 5px !important;
}
form .blends-select__indicator {
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.blends-select__input {
  margin-left: 25px !important;
}
