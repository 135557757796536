.frame-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 89px);
    justify-content: center;
    gap: 16px;
    box-shadow: 0px 2px 4px rgba(196, 196, 196, 0.4);
}

.frame-wrapper__item {
    display: flex;
    flex-direction: column;
    padding: 16px 7px 28px;
    border-radius: 5px;
    transition: all .15s ease-in-out;
    cursor: pointer;
}

.frame-wrapper__item:hover {
 background-color: #F3F6F4;   
}
.frame-wrapper__item.last {
    background-color: rgba(196, 196, 196, 0.35);
}
.frame-wrapper__item img {
    margin-bottom: 12px;
}

.frame-wrapper__item p {
    font-size: 14px;
    line-height: 1;
    font-weight: 400;
    color: #AAAAAA;
    text-align: center;
    margin-bottom: 5px;
}
.frame-wrapper__item.last p {
    color: #212121;
    font-weight: 700;
}
.frame-wrapper__item .popular {
    font-size: 10px;
    color: #FFF;
    background-color: #087E63;
    padding: 0px 3px;
    border-radius: 2px;
    width: fit-content;
    margin:  0 auto;

}

@media screen and (max-width: 767px) {
    .frame-wrapper {
        gap: 0px;
        margin-bottom: 9px;
        box-shadow: 0px 4px 20px rgba(196, 196, 196, 0.16);
    }
    .frame-wrapper__item {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 8px 0;
        border-radius: 0;
    }

    .frame-wrapper__item p {
        color: #c4c4c4;
        font-size: 12px;
        font-weight: 400;
    }
    .frame-wrapper__item.last {
        background-color: #F3F6F4;
        padding: 0px;
    }
    .frame-wrapper__item img {
        margin-bottom: 3px;
        height: 65px;
        width: 65px;
    }
    .frame-wrapper__item.last p {
        color: #212121;
    }
    .frame-wrapper__item .popular {
        position: absolute;
        bottom: 210px;
        font-size: 10px;
        color: #176A50;
        background-color: #C7EBD1;
        padding: 0px 9px;
        border-radius: 2px;
        width: fit-content;
        margin:  0 auto;
        font-weight: 500;
    }
    .frame-wrapper__item .popular::after {
        content:'';
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 0;
        height: 0;
        bottom: -4px;
        border-top: 4px solid #C7EBD1;
        border-left: 2px solid transparent;
        border-right: 2px solid transparent;
    }
}
