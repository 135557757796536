.mobile-icon-container {
    align-items: center;
    background-color: #FFF;
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(99, 76, 250, 0.1);
    display: flex;
    height: 50px;
    justify-content: center;
    left: 20px;
    padding: 0;
    width: 50px;
    position: fixed !important;
    z-index: 1;
}

.mobile-icon-container span {
    font-size: 1.2rem;
}
