.aboutus_mobile {
    width: 90vw;
    height: calc(100vh - 60px - 50px);
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  .aboutus_mobile_ht {
    width: 96%;
    height: 500px;
    margin-top: 0px;
    background-color: #fff;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15);
    border-radius: 14px;
  }
  
  .aboutus_mobile_header {
      text-align: center;
      padding-top: 12px;
      padding-bottom: 12px;
      font-weight: 700;
      font-size: 16px;
      box-shadow: 0px 4px 10px 4px rgba(204, 204, 204, 0.1);
      border-radius: 10px 10px 0px 0px;
      height: 60px;
      vertical-align: middle;
      line-height: 36px;
  }

  
  .aboutus_mobile_header_ht {
    text-align: center;
    padding-top: 12px;
    padding-bottom: 0;
    font-weight: 700;
    font-size: 24px;
    box-shadow: 0px 4px 20px 0px rgba(196, 196, 196, 0.16);
    border-radius: 10px 10px 0px 0px;
    height: 60px;
    vertical-align: middle;
    line-height: 36px;
}


  .aboutus_mobile_back_btn {
        position: relative;
        right: 25px;
        top: -40px;
        font-size: 14px;
        font-weight: 400;
    }
  
  .aboutus_mobile_content {
      height: calc(100vh - 163px - 115px);
      z-index: 1;
      overflow: auto;
      padding-left: 35px;
      padding-right: 55px;
      font-size: 14px;
      font-weight: 400;
  }

  
  
  .aboutus_mobile_content_ht {
    height: 510px;
    z-index: 1;
    overflow: auto;
    padding-bottom: 0;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 0px;
    font-size: 14px;
    font-weight: 400;
    margin-top: -30px;
    background-color: #fff;
}
  
  .aboutus_mobile_footer {
      height: 103px;
      box-shadow: 0px -4px 20px 1px rgba(204, 204, 204, 0.4);
      border-radius: 10px 10px 0px 0px;
      text-align: center;
      align-items: center;
      justify-content: center;
      display: flex;
      z-index: 2;
  }
  
  .aboutus_mobile_footer_nav {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 210px;
      line-height: 45px;
      background: #087E63;
      border: 1px solid white;
      border-radius: 10px;
      color: white !important;
      font-size: 15px;
      font-weight: 700;
      margin-bottom: 16px;
  }