.confirm-delete-modal {
  display: flex;
  flex-direction: column;
  width: calc(100% - 20px);
  gap: 10px;
  max-width: 540px;
  margin: 0 10px;
  bottom: 46px;
  position: absolute;
}
.confirm-delete__preview {
  background-color: #fff;
  padding: 29px 29px 21px;
  text-align: center;
  box-shadow: 0px 0px 15px 10px rgba(204, 204, 204, 0.5);
  border-radius: 11px;
}
.confirm-delete__preview img {
  width: 142px;
  margin-bottom: 25px;
  margin-top: 35px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  
}
.confirm-delete__preview h2 {
    font-size: 18px;
    line-height: 21px;
    font-weight: 600;
    color: #304036;
}
.confirm-delete__actions .description {
  color: #727272;
  text-align: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  max-width: 272px;
  direction: rtl;
  margin: auto;
  padding-top: 22px;
  padding-bottom: 22px;
}
.confirm-delete__actions {
  background-color: #f4f4f4;
  box-shadow: 0px 0px 15px 10px rgba(204, 204, 204, 0.5);
  border-radius: 11px;
}
.confirm-delete__actions button {
  background-color: transparent;
  border: none;
  outline: none !important;
  font-size: 14px;
  line-height: 16px;
  color: #304036;
  font-weight: 600;
  width: 100%;
  padding: 19px 0;
}
.confirm-delete__actions .delete {
  color: #ff3b30;
  border-top: 1px solid #727272;
}


/* .confirm-delete__actions .cancel {
  color: #007aff;
  border-top: 1px solid #ccc;
} */

.confirm-delete__cancel {
  background-color: #fff;
  box-shadow: 0px 0px 15px 10px rgba(204, 204, 204, 0.5);
  border-radius: 11px;
  
  button {
    background-color: #fff;
    border: none;
    outline: none !important;
    font-size: 14px;
    line-height: 16px;
    color: #007aff;
    font-weight: 500;
    width: 100%;
    padding: 19px 0;
    border-radius: 11px;
  }
}

@media screen and (max-width: 767px) {
    .confirm-delete-modal {
        bottom: 10px;
    }
}