.coupon-img-style {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: -25px;
}

.coupon-block, .coupon-block-mobile {
  max-width: 1200px;
  margin: auto;
  padding-top: 14px;
  padding-bottom: 14px;
}

.top-nav-spacing {
  margin-top: 57px;
}

@media screen and (max-width: 480px) {
  .top-nav-spacing {
    margin-top: 44px;
  }
}

.cta-arrow-container {
    position: absolute;
    left: calc(50% + 180px);
    top: -15px;
}

.cta-arrow-text {
  font-family: 'Ploni Yad 1.1 AAA';
  position: absolute;
  top: 0;
  left: 0;
  font-weight: 700;
  font-size: 16px;
  transform: rotate(11.67deg);
  color: black;
  width: 150px;
}

.wrapper-gallery {
  overflow: hidden;
  height: 16em;
  display: grid;
  /*grid-gap: 10px;*/
  scroll-snap-type: x mandatory;
  direction: rtl;
}

.gallery {
  font-family: Rubik;
  scroll-snap-type: x mandatory;
  overflow-y: hidden;
  display: flex;
  -webkit-overflow-scrolling: touch;
  overflow-x: scroll;
  white-space: nowrap;
}

.gallery-under-text {
  font-size: 14px;
  font-family: Rubik;
  font-weight: 300;
  margin-top: -2px;
}

.gallery-under-text, .gallery-under-text {
  color: #000;
}

.gallery img {
  object-fit: cover;
  height: 12.5em;
  border-radius: 8px;
  margin-bottom: 2px;
}

.gallery-3th, .gallery-2th, .gallery-1th {
  padding-right: 15px;
  scroll-snap-align: start;
  text-align: right;
  position: relative;
}

.gallery-4th {
  margin-left: 50px;
  scroll-snap-align: start;
  text-align: right;
  position: relative;
}

.gallery-under-images {
  display: flex;
  padding-right: 0 !important;
}

.gallery h5 {
  font-weight: 600;
  font-size: 16px;
}

.gallery-sale-cost, .gallery-sale-cost-first, .gallery-sale-cost-third {
  margin-right: 6.5em;
  font-size: 14px;
  color: #D4D4D4;
  font-weight: 500;
  text-decoration: line-through;
}

.gallery-sale-cost-first {
  margin-right: 7em;
}

.gallery-sale-cost-third {
  margin-right: 6em;
}

/* .gallery-sale-cost::after {
  content: "";
  position: absolute;
  bottom: 11%;
  left: 10.5%;
  width: 13%;
  height: 1px;
  background-color: #AAAAAA;
} */

.gallery-sale-cost:before, .gallery-sale-cost-first:before, .gallery-sale-cost-third:before {
  content: '₪';
  font-size: 6px;
}

.gallery-sale-cost-14 {
  margin-right: 6em;
  font-size: 14px;
  color: #D4D4D4;
  font-weight: 500;
  text-decoration: line-through;
}

/* .gallery-sale-cost-14::after {
  content: "";
  position: absolute;
  bottom: 11%;
  left: 6.5%;
  width: 13.5%;
  height: 1px;
  background-color: #AAAAAA;
} */

.gallery-sale-cost-14:before {
  content: '₪';
  font-size: 6px;
}

.gallery-sales-cloud, .gallery-sales-cloud-first {
  width: 1.7em;
  height: 1em;
  background-color: #265697;
  position: absolute;
  border-radius: 8px;
  left:22%;
  bottom: 9.5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-sales-cloud-first {
  left: 26%;
}

.gallery-sales-cloud p, .gallery-sales-cloud-first p{
  color: #FFFFFF;
  font-size: 8px;
}

.gallery-cost{
  font-size: 16px;
  margin-right: 4.5em;
  color: #00B189;
  font-weight: 500;
  margin-top: -2px;
}

.gallery-cost:before {
  content: '₪';
  font-size: 12px;
  color: #00B189;
  font-weight: 500;
}

.gallery-cost-14, .gallery-cost-6{
  font-size: 16px;
  margin-right: 4.5em;
  color: #00B189;
  font-weight: 500;
  margin-top: -2px;
}

.gallery-cost-6{
  margin-right: 4.2em;
}

.gallery-cost-14:before, .gallery-cost-6:before {
  content: '₪';
  font-size: 10px;
  color: #00B189;
  font-weight: 500;
}

.gallery-standard-cost {
  font-size: 14px;
  margin-right: 7em;
  color: #212121;
  font-weight: 500;
}

.gallery-standard-cost:before {
  content: '₪';
  font-size: 6px;
  color: #212121;
  font-weight: 500;
}

.gallery-4th{
    text-align: center;
    display: flex;
    align-items: center;
    min-width: 12em;
    margin-right: 3em;
}

.gallery-eye img {
  height: 50px !important;
  margin-left: 3em;
  margin-bottom: 1em;
}


.gallery-eye-text {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-button-continue {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-button-start {
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-button-start p, .gallery-button-continue p {
  margin-left: 3em;
}

.gallery-button-start:hover {
  color: #212121;
}

.gallery-button-start button {
  background-color: #FDCC0D;
  font-size: 15px;
  border-radius: 8px;
  height: 50px;
  width: 180px;
  border:none;
  color: #000;
  font-weight: 400;
  filter: drop-shadow(0px 3px 4px rgba(133, 133, 133, 0.20));
}

.gallery-link {
  display: block;
  color: #000000;
  margin-top: 45%;
}

.gallery-arrow {
  size: 30px;
}

.gallery-link:hover {
  color: inherit;
  text-decoration:none;
  cursor:pointer;
}

.gallery::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.israel {
  width: 30px;
  margin-left: 4px;
  margin-bottom: 12px;
}
.standwithisrael {
  align-items: center;
  padding-bottom: 15px;
  margin-left: auto;
  margin-right: auto;
  display: flex;

  @media screen and (min-width:767px) {
      height: 60px;
      width: 12em;
  }
}

.standwithisrael {
  direction: rtl;
  font-size: 14px;
  display: flex;
  justify-content: start;
  border: 1px solid #4C7DFA;
  background: #EEF3FF;
  border-radius: 8px;
  height: 50px;
  margin: auto;
  margin-top: 80px;


  @media screen and (max-width: 767px) {
    width: 360px;


  
  @media screen and (max-width: 767px) {
    width: 360px;
    

  @media screen and (max-width: 370px) {
    width: 320px;
  }
  }
}
}

.standwithisrael img {
  height: 30px;
  margin-right: 18px;
  margin-bottom: 12px;
  margin-top: 30px;
}

@media screen and (min-width: 800px) {
  .standwithisrael img {
    height: 33px;
  }
}

.standwithisrael p {
  padding-left: 10px;
  padding-right: 16px;
  padding-top: 15px;
}

.payboxdonation {
  text-decoration: underline;
  color: blue;
}
.leftarrow {
  margin-top: 2px;
  width: 16px;
}
.first-block-on-gallery {
  display: flex;
  direction: rtl;
  padding-right: 5px;
  padding-bottom: 10px;
}

.first-block-on-gallery p {
  font-weight: 600;
  font-size: 16.06px;
  margin-right: 5px;
  color: #000000;
  margin-top: 2px;
}

.first-block-on-gallery img {
  margin-right: 5px;
}

.first-block-second-text {
  color: #00B189 !important;
}

.second-block-on-gallery {
  display: flex;
  direction: rtl;
  padding-right: 5px;
  margin-bottom: 0.5em;
}

.second-block-on-gallery img {
  margin-right: 5px;
  height: 24px;
}

.second-block-on-gallery p {
  margin-right: 6px;
  margin-bottom: 1em;
  font-family: Assistant;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.grey-line, .first-grey-line {
  border-width: 4px;
  border-color: #D8D8D8;
}

.grey-line {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.first-grey-line {
  margin-bottom: 1em;
}

.mobile-homepage-lowmid-button {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  margin-bottom: 1em;

}

.mobile-homepage-lowmid-button button {
  color: #000;
  width: 300px;
  height: 50px;
  background-color: #FDCC0D;
  border: none;
  border-radius: 8px;
  filter: drop-shadow(0px 3px 4px rgba(133, 133, 133, 0.20));
}

.additional-block{
  background-color: #fff;
  display: flex;
  gap: 2em;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
}

.additional-block-first {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.additional-block-first-text {
  font-weight: 700;
  font-size: 26px;
  margin-bottom: 0.5em !important;
  width: 240px;
  line-height: 30px;
  width: 200px;

  @media screen and (max-width: 370px) {
    font-size: 26px;
  }
}

.additional-block-second-text {
  font-weight: 400;
  font-size: 16.06px;
}

.additional-block-second {
  display: flex;
  justify-content: center;
  align-items: center;
}

.additional-block-second p {
  text-align: right !important;
  direction: rtl;
  width: 170px;
  line-height: 20px;
  font-size: 10.7px !important;
}

.additional-block-second img {
  height: 23px;
  margin-left: 1em;

}

.additional-block-text {

}

.gift-icons-text {
  background-color: #FFFDFB;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gift-icons {
  display: flex;
  flex-direction: column;
}

.first-gift-icons, .second-gift-icons {
  display: flex;
}

.first-gift-icons{
  display: flex;
  margin-top: 2em;
}

.second-gift-icons {
  display: flex;
  margin-top: 2em;
  margin-bottom: 2em;
}

.birthday-cake-icon, .baby-icon, .wedding-ring-icon, .thanks-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 5.5em;
  height: 5em;
}

.wedding-ring-icon, .thanks-icon {
  margin-right: 2em;
}

.birthday-cake-icon img, .baby-icon img, .wedding-ring-icon img, .thanks-icon img {
  margin-bottom: 1em;
  width: 36px;
  height: 36px;
}

.gift-top-text {
  font-weight: 700;
  font-size: 26px;
  margin-top: 1em;
}
