.aboutus {
    max-width: 770px;
    width: 100%;
    height: 500px;
    background-color: #fff;
    box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    position: absolute;
    z-index: 1010;
}

.aboutus_header {
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    font-weight: 700;
    font-size: 18px;
    box-shadow: 0px 4px 10px 4px rgba(204, 204, 204, 0.4);
    border-radius: 10px 10px 0px 0px;
    height: 60px;
    vertical-align: middle;
    line-height: 36px;
    direction: ltr;
}

.aboutus_content {
    height: 337px;
    z-index: 1;
    overflow: auto;
    padding-left: 85px;
    padding-right: 85px;
    padding-top: 42px;
    font-size: 16px;
    font-weight: 400;
    
    @media screen and (max-width: 1024px) {
        padding-left: 20px;
        padding-right: 30px;
    }
}

.aboutus_footer {
    height: 103px;
    box-shadow: 0px -4px 6px 1px rgba(204, 204, 204, 0.1);
    border-radius: 10px 10px 0px 0px;
    text-align: center;
    line-height: 103px;
    z-index: 2;
}

.aboutus_footer a {
    cursor: pointer;
    display: inline-block;
    height: 45px;
    width: 175px;
    line-height: 30px;
    background: #FFFFFF;
    border: 5px solid #F2F2F2;
    border-radius: 5px;
    color: #087E63 !important;
    font-size: 15px;
    font-weight: 400;
}