.add-card-modal-mobile {
    position: absolute;
    display: flex;
    flex-direction: column;
    padding: 8px 10px;
    background: #FFFFFF;
    box-shadow: 0px -4px 10px 5px rgba(255, 247, 247, 0.20);
    border-radius: 14px;
    max-width: 400px;
    left: 10px !important;
    right: 10px !important;
    border: none;
}

.add-card-modal-mobile img {
    height: 25px;
}

.bitpayment {
    font-weight: 500 !important;
    font-size: 15px;
}

.add-card-modal-mobile button:disabled {
    color: darkgray !important;
}

.bitpayment img {

    margin: 0 auto;
    height: 25px;
}
.add-card-modal-mobile button {
    background-color: transparent;
    color: #212121;
    border: none;
    outline: none!important;
    padding: 16px 12px;
    text-align: right;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 45px;
    gap: 16px;
    font-size: 15px;
    font-weight: 600;
    font-family: 'Rubik';
    width: 100%;
    text-align: right;
}
.add-card-modal-mobile button img {
    margin: 0 auto;
}
.add-card-modal-mobile ul {
    border-top: 1px solid #f0f0f0;
}
.add-card-modal-mobile ul li {
    left: 0;
    line-height: 1;
    width: 100%;
    border-bottom: 1px solid #f0f0f0;
}
.add-card-modal-mobile ul li:last-child {
    border-bottom: none;
}

.buttonspinner {
    position: fixed;
    border: 2px solid rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    border-top: 2px solid #cccccc;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }