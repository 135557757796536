.heroicons {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 1.4em;
}

.icon-container {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Adjust spacing as needed */
    flex-direction: row-reverse; /* Reverse the order of flex items */
    
    img {
        height: 2.6em;
    }
}

.icon {
    margin-left: 10px; /* Space between icon and text */
}

.icon-text {
    font-size: 16px;
    font-weight: 500;
    text-align: right; /* Aligns text to the right */
}
