@media screen and (min-width: 767px) {
    .add-bw-filter {
        z-index: 110;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
    }

    .add-bw-filter.show{
        opacity: 1;
        pointer-events: visible;
    }

    .add-bw-filter-content {
        width: 315px;
        height: 186px;
        display: grid;
        background-color: #fff;
        position: fixed;
        border-radius: 10px;
        padding: 20px;
    }

    .add-bw-filter-header p{
        font-family: Rubik;
        font-size: 18px;
        font-weight: 600;
        line-height: 36px;
        text-align: right;
    }

    .add-bw-filter-body p {
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 36px;
        text-align: right;
    }

    .add-bw-filter-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .add-bw-filter-text {
        cursor: pointer;
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 36px;
        text-align: center;
    }

    .add-bw-filter-button-close {
        height: 50px;
        width: 133px;
        margin-left: 24px;
        background-color: #087E63;
        border: none;
        color: white;
        text-decoration: none;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 36px;
        text-align: center;
    }
}


@media screen and (max-width: 767px) {
    .add-bw-filter {
        z-index: 110;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
    }

    .add-bw-filter.show{
        opacity: 1;
        pointer-events: visible;
    }

    .add-bw-filter-content {
        width: 315px;
        height: 186px;
        display: grid;
        background-color: #fff;
        position: fixed;
        border-radius: 10px;
        padding: 20px;
    }

    .add-bw-filter-header p{
        font-family: Rubik;
        font-size: 18px;
        font-weight: 600;
        line-height: 36px;
        text-align: right;
    }

    .add-bw-filter-body p {
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 36px;
        text-align: right;
    }

    .add-bw-filter-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .add-bw-filter-text {
        cursor: pointer;
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 36px;
        text-align: center;
    }

    .add-bw-filter-button-close {
        height: 50px;
        width: 133px;
        margin-left: 24px;
        background-color: #087E63;
        border: none;
        color: white;
        text-decoration: none;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Rubik;
        font-size: 14px;
        font-weight: 400;
        line-height: 36px;
        text-align: center;
    }
}
