.swiper-container {
}

@media screen and (min-width: 1200px) {
  .swiper-overlay-desktop {
    pointer-events: none;
    position: absolute;
    width: 1234px;
    height: 320px;
    z-index: 90;
    background:  linear-gradient(90deg, #F3F6F4 6%, rgba(243, 246, 244, .5) 10%,transparent 20%, transparent,transparent 80%, rgba(243, 246, 244, .5) 85%, #F3F6F4 97%), linear-gradient(to bottom, #F3F6F4 0%, transparent 0%);
  }

  .desktop-footer-cta {
    color: #087E63;
    background-color: #FFFFFF;
  
  }
  
}

.footer-row {
  max-width: 1280px;
}

.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 52px;
  overflow: unset;
  }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  vertical-align: middle;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.imgBox {
  height: 312px;
  overflow: hidden;
  position: relative;
  /* box-shadow: 0px 0px 4px rgba(194, 194, 194, 0.25); */
  border-radius: 10px;
}

.imgBox img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.bgOverlay {
  width: 100%;
  height: 44%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    #DCE5DF00 0.19%,
    #000000 108.6%
  )
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}


.mobileBgOverlay {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background:  linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, .7) 1%, transparent,rgba(255, 255, 255, .7) 100%, rgba(255, 255, 255, 1) 100%);
}

/* .mobile-swiper-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background:  linear-gradient(
    180deg,
    #DCE5DF00 50%,
    #A0B7A8 108.6%
  );
} */

.textOverlay {
  position: absolute;
  bottom: 10px;
  padding: 10px;
  color: #fff;
  direction: rtl;
  right: 15px;
}

.textOverlay p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 15px;
  text-align: right;
}

.textOverlay h5 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 15px;
  color: #fff;
  text-align: right;
}

.swiper-pagination-bullet {
  background-color: #D0DCD4;
  height: 8px;
  width: 8px;
  opacity: 1;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #304036;
  height: 8px;
  width: 8px;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 2px;
}

.prev-slide,
.next-slide {
  border: none;
  outline: none!important;
  background: #ffffffc2;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  width: 70px;
  height: 70px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}
.next-slide {
    right: 0px;
}
.prev-slide {
    left: 0px;
}
@media screen and (max-width: 480px) {
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
  }
  
}

.swiper-wrapper, .swiper {
  max-width: 1200px;
  /* background: red; */
}

.myswiper .swiper-pagination-bullet-active-main {
  background-color: #FF007C !important;
  width: 12px !important;
  height: 12px !important;
}

.swiper-pagination-bullet .swiper-pagination-bullet-active-main {
  margin: 0 !important;
}

.mySwiperHome {
  max-width: 1200px;
  position: relative;
}

.mySwiperHome .swiper-slide {
  background-color: #F3F6F4;
}

.swiper_left {
  height: 320px;
  width: calc((100vw - 1200px) / 2 + 35px);
  position: absolute;
  background-color: #F3F6F4;
  left: 0px;
  z-index: 40;
}

.swiper_right {
  height: 320px;
  width: calc((100vw - 1200px) / 2 + 35px);
  position: absolute;
  background-color: #F3F6F4;
  right: 0px;
  z-index: 40;
}

.google-container img {
  width: auto;
}

/* .mySwiperHome .prev-slide {
  left: 35px;
} */

/* .mySwiperHome .next-slide {
  right: 35px;
} */

.next-slide {

}

@media screen and (max-width: 768px) {
  
  .swiper {
    padding-bottom: 25px;
  }
  .swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 1.5px;
  }
  .swiper-pagination-bullet {
    height: 8px !important;
    width: 8px !important;
  }
  .swiper-pagination-bullet .swiper-pagination-bullet-active-main {
    margin: 0 !important;
  }

  .swiper-pagination-bullet-active-main {
    height: 8px !important;
    width: 8px !important;
  }
  
  .swiper-slide {
    padding-bottom: 15px;
  }
  .mySwiperHome .swiper-pagination {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
  }
  
  .imgBox {
    border-radius: 0px;
  }
}

@media screen and (min-width: 1440px) {
  .swiper_left {
    height: 320px;
    width: calc((100vw - 1200px) / 2 + 30px);
    position: absolute;
    background-color: #F3F6F4;
    left: 0px;
    z-index: 40;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
  }
  
  .swiper_right {
    height: 320px;
    width: calc((100vw - 1200px) / 2 + 30px);
    position: absolute;
    background-color: #F3F6F4;
    right: 0px;
    z-index: 40;
  }
}

.mobile-comment-container {
  padding-left: 35px;
  padding-right: 35px;
  padding-bottom: 25px;
  padding-top: 10px;
}

.mobile-comment-container p {
  direction: rtl;
}