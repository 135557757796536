.modal-overlay-wrapper {
   overflow: hidden;
}
.modal-overlay {
    display: flex;
    height: 100vh;
    width: 100vw;
    align-items: center;
    justify-content: center;
    transition: all .4s ease-in-out;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
}

.bounce-down-enter {
    bottom: -150%;
}

.bounce-down-enter.bounce-down-enter-active {
    bottom: 15px;
    transition: opacity 300ms ease-in;
}

.bounce-down-leave {
    bottom: 15px;
}

.bounce-down-leave.bounce-down-leave-active {
    bottom: -150%;
    transition: bottom 300ms ease-in;
}

.modal-overlay.show {
}

.modal-overlay.hide {
   pointer-events: none;
}

.css-transition-enter {
    opacity: 0;
  }
  .css-transition-enter-active {
    opacity: 1;
    transition: opacity .3s ease-in-out;
  }
  .css-transition-enter-done {
    opacity: 1;
  }
  .css-transition-exit {
    opacity: 1;
  }
  .css-transition-exit-active {
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }
  .css-transition-exit-done {
      opacity: 0;
  }