.mobile-banner {
    padding-top: 48px;
    padding-bottom: 8px;
    margin-right: 1.4em;
    text-align: right;
  }
  
  .mobile-banner h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
    color: #303030;
  }
  
  .hero-images-3 {
    display: flex;
    justify-content: end;
    margin-right: 1.4em;
    margin-bottom: 1em

  }
  
  .hero-images-3 img {
    width: 290px;
    border-radius: 20px;
  }
  
  .hero-image-1  {
    width: 140px;
    border-radius: 20px;
  }
  
  .hero-image-2  {
    width: 187px;
    border-radius: 20px;
  }
  
  .hero-images {
    display: flex;
    justify-content: end;
    margin-bottom: 14px;
    margin-right: 1.4em;
    gap: 14px;
  }
  
  /* .heroicons {
    margin-right: 1.4em;
    padding-top: 8px;
    direction: rtl;
    
    img {
        width: 2.6em;
        margin-bottom: 1em;
        margin-left: 1em;
    }
  } */
  
  #gradientDiv {
    height: 10px;
    background: #F3F6F4;
}