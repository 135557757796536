
.coupon-input-wrapper.error .coupon-input  {
  border-color: #CF3838;
}

.coupon-input {
  position: relative;
}
.coupon-input-wrapper.error .coupon-input {
  border-color: #CF3838;
}
.coupon-input {
  border: 1px solid #727272;
  border-radius: 8px 0 0 8px;
  height: 56px;
  padding: 5px 20px;
  width: calc(100% - 30px);
  outline: none;
  border-right: 0;
  font-size: 11px;
  color: #212121;
  text-align: right;
}

.loader-img {
  width: 24px;
  height: 24px;
  margin: auto 12px;
}

.show-coupon-container {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.show-coupon-btn {
  color: #727272;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  direction: rtl;
}

.show-coupon-btn:focus {
  outline: 0;
 }

 .show-coupon-img {
  width: 18;
  height: 18;
 }

 .action-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 55px !important;
  max-height: 36px;
  margin-top: 8px;
 }
 
 .action-container img {
  margin-bottom: 16px;
 }
 
 .discountrollback {
  background-color: #f2f2f2;
  border-radius: 10px;
  width: 50px;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 300;
  font-size: 12px;
 }

.check-btn {
  color: #087E63;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  direction: rtl;
}
.check-btn:focus {
  outline: 0;
 }


.remove-btn {
  color: #727272;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  direction: rtl;
}

.remove-btn:focus {
  outline: 0;
 }

 .input-group-coupon-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 26px;
  margin-bottom: 8px;
}

.input-group-coupon {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}

.input-group-adornment {
  display: flex;
  gap: 0;
}

.coupon-input-group-text {
  display: flex;
  align-items: center;
  height: 56px;
  border: 1px solid #727272;
  background: #fff;
  border-radius: 0 8px 8px 0;
  padding-right:  20px;
  border-left: 0;
}

.discountspinner{
  border: 2px solid rgba(155, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 2px solid #087E63;
  width: 16px;
  height: 16px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.coupon-divider {
  border-left: 1px solid #727272;
  height: 16px;
  padding-right: 15px;
}

.invalid-coupon-input {
  border: 1px solid  #CF3838;
  color: #CF3838;
  border-right: 0;
}

.invalid-coupon-input-group-text {
  border: 1px solid #CF3838;
  border-left: 0;
}

.valid-coupon-input:disabled {
  background-color: white;
}

.valid-coupon-input-group-text {
  border: 1px solid #36BD6C;
}

.info-text {
  height: 18px;
}

.error-text-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  gap:5px;
  height: 18px;

}

.error-text {
  color: #CF3838;
  font-size: 9px;
  direction: rtl;
}

.error-text img {
  width: 10px;
  height: 10px;
}

.rollback-img {
  width: 18px;
  height: 18px;
}


.remove-text {
  color: #56C783;
  font-family: Rubik;
  font-size: 9px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: right;
  height: 18px;
  direction: rtl;
}

.text-hidden {
  color: white;
}

.valid-container {
  background-color: #f2fbf9;
  border: 1px solid #36BD6C;
  border-radius: 8px;
  height: 56px;
  padding: 5px 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: calc(100% + 60px);
}

.valid-icon {
  width: 20px;
  height: 20px;
}

.valid-container-text {
  margin-right: 10px;
}

.valid-container-text-row {
  display: flex;
  justify-content: flex-end;
  gap: 3px
}
.first-text-coupon {
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: right;
  color: #212121;
  direction: rtl;
}

.first-text {
  direction: rtl;
}

.second-text {
  font-family: Rubik;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: right;
  color: #727272;
  direction: rtl;
}




