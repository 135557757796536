.thumbnail-container {
    width: 88vw;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    border-radius: 11px;
    margin-top: 26px;
    margin-bottom: 26px;
  }
  
.thumbnail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px;
    border-color: white;
  }
  
  .text-box {
    padding: 5px;
    direction: rtl;
    text-align: center;
    background-image: url('https://res.cloudinary.com/dzb3a5brn/image/upload/s--eG8OmePi--/v1710514919/textbox_onryee.png');
    background-size: cover;
    width: 10em;
    border-radius: 11px;
    color: #026159;
    line-height: 16px;
  }
  
  .main-text {
    font-size: 22px;
    direction: rtl;
    font-weight: 700;
    color: #026159;
    margin-right: 8px;
    margin-left: 4px;
  }
  
  .text-box-title {
    font-size: 16px;
    font-weight: 600;
  }
  
  .text-box-2nd {
    font-size: 16px;
  }
  
  .text-box-3rd {
    font-size: 12px;
  }
  