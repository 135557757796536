@media screen and (min-width: 767px) {
    .second-screen {
        z-index: 100;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        /*transition: all 0.3s ease-in-out;*/
        pointer-events: none;
    }

    .second-screen.show{
        opacity: 1;
        pointer-events: visible;
    }

    .second-screen-content {
        width: 567px;
        height: 455px;
        background-color: #FFFDFB;
        position: fixed;
        border-radius: 10px;
    }

    .second-screen-gradient-footer {
        width: 567px;
        height: 100px;
        content:'';
        left: 0;
        top: 78%;
        right: 0;
        bottom: 0;
        position:absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(rgba(255,255,255,0) 0%, rgba(140, 233, 190, 0.25) 100%);
        border-radius: 10px;
    }

    .second-screen-header .second-screen-footer {
        padding: 10px;
    }

    .second-screen-header {
        margin: 3% 0 0 0;
    }

    .second-screen-body {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        text-align:center;
    }

    .second-screen-footer {
        padding: 10px;
    }

    .second-screen-container {
        width: 100%;
    }

    .second-screen-progressbar {
    }

    .second-screen-active-inside {
        content: "";
        width: 10px;
        height: 10px;
        line-height : 30px;
        border: 1px solid #087E63;
        border-radius: 3px;
        display: block;
        text-align: center;
        margin: -2.02em 0.75em 0 auto;
        background-color: #087E63;
        animation: easeInTransform 0.3s forwards;
    }

    .second-screen-progressbar li {
        left: -18em;
        list-style: none;
        display: inline-block;
        width: 6%;
        position: relative;
        text-align: center;
    }

    .second-screen-progressbar li:before {
        content: "";
        width: 15px;
        height: 15px;
        line-height : 30px;
        border: 1px solid #AAAAAA;
        border-radius: 5px;
        display: block;
        text-align: center;
        margin: 0 auto 20px auto;
        background-color: white;
    }

    .second-screen-progressbar li:after {
        content: "";
        position: absolute;
        width: 35%;
        height: 1px;
        background-color: #AAAAAA;
        top: 7px;
        left: -18%;
        z-index : -1;
    }

    .second-screen-progressbar li:first-child:before {
        background-color: #CCCCCC;
    }

    .second-screen-progressbar li:last-child:after {
        content: none;
    }

    .second-screen-progressbar li.second-screen-active {
        color: green;
    }

    .second-screen-progressbar li.second-screen-active:before {
        border-color: #AAAAAA;
    }

    .second-screen-progressbar li.second-screen-active + li:after {
        background-color: #AAAAAA;
    }

    .second-screen-top-text {
        color: #000000;
        font-size: 12px;
        text-align: center;
        margin-top: 1em;
        margin-left: 6.2em;
        display: flex;
        margin-bottom: 1em;
    }

    .second-screen-first-top-text {
        margin-right: 2.8em;
    }

    .second-screen-first-under-top-text {
        left: 16.1% !important;
    }

    .second-screen-under-top-text > *{
        top: 11.5%;
        left: 28.1%;
        position: absolute;
        height: 0.6em;
        border-left: 1px solid #000000;
    }

    .second-screen-first-text {
        margin: 2em 0.7em 0.3em auto;
        text-align: right;
        font-weight: 700;
        animation:fadeInUp 1s ease forwards;
        font-size: 26px;
        direction: rtl;
    }

    .second-screen-option-img {
        margin-right: 16px;
        margin-left: 16px;
        width: 50px;
    }

    .second-screen-second-text {
        margin-bottom: 2em;
        text-align: right;
        margin-right: 1.25em;
        font-size: 15px;
        animation:fadeInUp 1.25s ease forwards;
    }

    .second-screen-option-hr {
        width: 18em;
        border-bottom: 0 solid #F4F4F4;
        margin: auto;
    }

    .second-screen-options {
        width: 314px;
        height: 166px;
        background-color: #FFFFFF;
        box-shadow: 0 5px 5px rgba(204, 204, 204, 0.5);
        margin: 0 auto;
        display: grid;
        align-items: center;
        animation:fadeInUp 1.5s ease forwards;
    }

    .second-screen-first-option, .second-screen-second-option {
        display: flex;
        justify-content: right;
        align-items: center;
        height: 81.5px;
    }

    .second-screen-first-option:hover, .second-screen-second-option:hover {
        cursor: pointer;
    }

    .second-screen-options-text {
        cursor: pointer;
        font-weight: 700;
    }

    .second-screen-progress {
        opacity: 0;
        position: absolute;
    }

    .second-screen-skip {
        opacity: 0;
        position: absolute;
        font-size: 14px;
    }

    .second-screen-progress-skip {
        opacity: 0;
        position: absolute;
    }

    .second-screen-arrow {
        position: absolute;
        left: 41%;
        top: 8.5%;
    }

    .second-screen-arrow-text {
        position: absolute;
        left: 45%;
        top: 6%;
        font-weight: 400;
        font-size: 12px;
        rotate: 10deg;
    }
}

@media screen and (max-width: 767px) {
    .second-screen {
        z-index: 100;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        /*transition: all 0.3s ease-in-out;*/
        pointer-events: none;
    }

    .second-screen.show{
        opacity: 1;
        pointer-events: visible;
    }

    .second-screen-content {
        width: 100%;
        height: 80%;
        background-color: #FFFDFB;
        position: fixed;
        bottom: 0;
        border-radius: 10px 10px 0 0;
    }

    .second-screen-header .second-screen-footer {
        padding: 10px;
    }

    .second-screen-header {
        margin: 3% 0 0 0;
    }

    .second-screen-body {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        text-align:center;
    }

    .second-screen-footer {
        padding: 10px;
    }

    .second-screen-container {
        width: 100%;
        opacity: 0;
        position: absolute;
    }

    .second-screen-progressbar {
    }

    .second-screen-active-inside {
        content: "";
        width: 12px;
        height: 12px;
        line-height : 30px;
        border: 1px solid #087E63;
        border-radius: 4px;
        display: block;
        text-align: center;
        margin: -2.25em 3.15em 0 auto;
        background-color: #087E63;
    }

    .second-screen-progressbar li {
        left: -3.5em;
        list-style: none;
        display: inline-block;
        width: 20%;
        position: relative;
        text-align: center;
    }

    .second-screen-progressbar li:before {
        content: "";
        width: 20px;
        height: 20px;
        line-height : 30px;
        border: 1px solid #AAAAAA;
        border-radius: 8px;
        display: block;
        text-align: center;
        margin: 0 auto 20px auto;
        background-color: white;
    }

    .second-screen-progressbar li:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #AAAAAA;
        top: 10px;
        left: -50%;
        z-index : -1;
    }

    .second-screen-progressbar li:first-child:before {
        background-color: #727272;
    }

    .second-screen-progressbar li:last-child:after {
        content: none;
    }

    .second-screen-progressbar li.second-screen-active {
        color: green;
    }

    .second-screen-progressbar li.second-screen-active:before {
        border-color: #AAAAAA;
    }

    .second-screen-progressbar li.second-screen-active + li:after {
        background-color: #AAAAAA;
    }

    .second-screen-top-text {
        color: #B5B5B5;
        font-size: 12px;
        text-align: left;
        margin-left: 8.2em;
    }

    .second-screen-first-text {
        margin-top: 66px;
        margin-bottom: 26px;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        animation:fadeInUp 1s ease forwards;
        direction: rtl;
    }
    
    .onboarding-indicator {
        position: fixed;
        bottom: 10%;
        font-weight: 300;
        font-size: 12px;
    }

    .second-screen-option-img {
        margin-right: 16px;
        margin-left: 16px;
        width: 40px;
    }

    .second-screen-second-text {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 1em !important;
        font-size: 16px;
        width: 95%;
        margin: auto;
        margin-bottom: 3em;
        animation:fadeInUp 1.25s ease forwards;
    }

    .second-screen-option-hr {
        width: 18em;
        border-bottom: 0 solid #F4F4F4;
        margin: auto;
        color: #F4F4F4;
    }
    .onboarding-smallt-mob {
        font-size: 12px;
        display: flex;
        justify-content: flex-end;
        margin-top: 16px;
        
        img {
            margin-left: 6px;
            width: 25px;
        }
        
        p {
            margin-top: 5px;
            direction: rtl;
        }
    }
    
    .second-screen-options {
        width: 314px;
        height: 166px;
        background-color: #FFFFFF;
        box-shadow: 1px 2px 15px 0px rgba(204, 204, 204, 0.30);
        margin: auto;
        margin-top: 56px;
        margin-bottom: 16px;
        display: grid;
        align-items: center;
        animation:fadeInUp 1.5s ease forwards;
        border-radius: 8px;
    }

    .second-screen-first-option, .second-screen-second-option {
        display: flex;
        justify-content: right;
        align-items: center;
        height: 81.5px;
    }

    .second-screen-first-option:hover, .second-screen-second-option:hover {
        cursor: pointer;
    }

    .second-screen-options-text {
        cursor: pointer;
        font-weight: 500;
        font-size: 16px;
    }

    .second-screen-progress {
        z-index: 200;
        position: absolute;
        opacity: 1;
        display: flex;
        bottom: 5%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .second-screen-first-circle, .second-screen-second-circle {
        border-radius: 50%;
        width: 0.5em;
        height: 0.5em;
        margin-left: 0.2em;
        margin-right: 0.2em;
    }

    .second-screen-first-circle {
        background-color: #CCCCCC;
    }

    .second-screen-second-circle {
        background-color: #CCCCCC;
    }

    .second-screen-line {
        animation: fadeInProgress 1.25s forwards;
        border-radius: 1em;
        width: 1.75em;
        height: 0.5em;
    }

    .second-screen-skip {
        z-index: 200;
        margin-top: 2.5em;
        opacity: 1;
        cursor: pointer;
        position: absolute;
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        font-size: 16px;
        font-weight: 500;
    }

    .second-screen-progress-skip {
        opacity: 1;
        display: flex;
        align-items: center;
        margin-top: 34%;
        justify-content: center;
    }

    .second-screen-arrow {
        position: absolute;
        opacity: 0;
    }

    .second-screen-arrow-text {
        position: absolute;
        opacity: 0;
    }
}

@keyframes easeInTransform {
    0% {
        opacity: 0;
        scale: 0;
    }
    100% {
        opacity: 1;
        scale: 1;
    }
}

@keyframes fadeInUp {
    0% {
        transform:translate(0px, -10px);
        opacity: 0;
    }
    100% {
        transform:translate(0px, 0);
        opacity: 1;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInProgress {
    0% {
        background-color: #CCCCCC;
    }
    100% {
        background-color: #212121;
    }
}
