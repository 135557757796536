.video-container {
    position: relative;
    margin: auto;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 11px;
  }
  
  .video-player {
    width: 88vw;
    border-radius: 11px;
  }
  
  .video-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    display: block; /* default display */
  }
  
  .video-overlay.hide {
    display: none; /* hide overlay when playing */
  }
  
  .play-button {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #FFFFFF;
    color: #087E63;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
  }
  
  .play-button span {
    margin-right: 8px;
  }
  