@media screen and (min-width: 767px) {
    .third-screen {
        z-index: 100;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
    }
    
    .onboarding-indicator {
        display: none;
    }

    .third-screen.show{
        opacity: 1;
        pointer-events: visible;
    }

    .third-screen-content {
        width: 567px;
        height: 455px;
        background-color: #FFFDFB;
        position: fixed;
        border-radius: 10px;
    }

    .third-screen-gradient-footer {
        width: 567px;
        height: 100px;
        z-index: 150;
        content:'';
        left: 0;
        top: 78%;
        right: 0;
        bottom: 0;
        position:absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(rgba(255,255,255,0) 0%, rgba(140, 233, 190, 0.25) 100%);
        border-radius: 10px;
    }

    .third-screen-header .third-screen-footer {
        padding: 10px;
    }

    .third-screen-header {
        margin: 3% 0 0 0;
    }

    .third-screen-body {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        text-align:center;
    }

    .third-screen-footer {
        padding: 10px;
    }

    .third-screen-container {
        width: 100%;
        opacity: 1;
    }

    .third-screen-progressbar {
    }

    .third-screen-active-inside {
        content: "";
        width: 10px;
        height: 10px;
        line-height : 30px;
        border: 1px solid #087E63;
        border-radius: 3px;
        display: block;
        text-align: center;
        margin: -2.02em 0.75em 0 auto;
        background-color: #087E63;
        animation: easeInTransform 0.3s forwards;
    }

    .third-screen-progressbar li {
        left: -18em;
        list-style: none;
        display: inline-block;
        width: 6%;
        position: relative;
        text-align: center;
    }

    .third-screen-progressbar li:before {
        content: "";
        width: 15px;
        height: 15px;
        line-height : 30px;
        border: 1px solid #AAAAAA;
        border-radius: 5px;
        display: block;
        text-align: center;
        margin: 0 auto 20px auto;
        background-color: white;
    }

    .third-screen-progressbar li:after {
        content: "";
        position: absolute;
        width: 35%;
        height: 1px;
        background-color: #AAAAAA;
        top: 7px;
        left: -18%;
        z-index : -1;
    }

    .third-screen-progressbar li:first-child:before {
        background-color: #CCCCCC;
    }

    .third-screen-progressbar li:nth-child(2):before {
        background-color: #CCCCCC;
    }

    .third-screen-progressbar li:last-child:after {
        content: none;
    }

    .third-screen-progressbar li.third-screen-active {
        color: green;
    }

    .third-screen-progressbar li.third-screen-active:before {
        border-color: #AAAAAA;
    }

    .third-screen-progressbar li.third-screen-active + li:after {
        background-color: #AAAAAA;
    }

    .third-screen-top-text {
        color: #000000;
        font-size: 12px;
        text-align: center;
        margin-top: 1em;
        margin-left: 6.2em;
        display: flex;
        margin-bottom: 1em;
    }

    .third-screen-first-top-text {
        margin-right: 2.8em;
    }

    .third-screen-first-under-top-text {
        left: 16.1% !important;
    }

    .third-screen-under-top-text > *{
        top: 11.5%;
        left: 28.1%;
        position: absolute;
        height: 0.6em;
        border-left: 1px solid #000000;
    }

    .third-screen-button-upload {
        height: 10%;
        width: 50%;
        z-index: 200;
        margin: 1em 0 0 0;
        position: absolute;
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        background-color: #087E63;
        border: none;
        color: #FFBD45;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
    }

    .third-screen-text-button {
        margin-bottom: 2px;
    }
    
    .third-screen-button-upload:hover {
     background-color: #0b9371;   
    }

    .third-screen-first-input, .third-screen-second-input {
        display: flex;
        margin-bottom: 0.5em;
    }

    .third-screen-input-name, .third-screen-input-email {
        background-color: #f7f7f7;
        width: 55%;
        height: 3em;
        margin-left: 38%;
        padding-top: 8px;
        padding-bottom: 8px;
        outline: none;
        line-height: 36px;
        border: 1.5px solid #727272;
        direction: rtl;
        font-size: 14px;
        border-radius: 8px;
        animation:fadeInUp 1.25s ease forwards;
        padding-right: 16px;
    }

    .third-screen-input-name.focus, .third-screen-input-email.focus {
        border-color: #087E63;
    }

    .third-screen-input-name.error, .third-screen-input-email.error {
        border-color: #FF9494;
    }

    .third-screen-input-name:not(:placeholder-shown).error, .third-screen-input-email:not(:placeholder-shown).error {
        border-color: #FF9494;
    }

    .third-screen-icon {
        margin-left: 1em;
        animation:fadeInUp 1.25s ease forwards;
    }

    .third-screen-checkbox-container{
        display: flex;
        justify-content: end;
        align-items: center;
        margin-right: 1%;
    }

    .third-screen-input-name:focus::placeholder, .third-screen-input-email:focus::placeholder{
        opacity: 0.5;
    }

    .third-screen-input-name::placeholder, .third-screen-input-email::placeholder {
        color: #3A3A3A;
        opacity: 1;
        font-weight: 500;
    }

    .third-screen-input-name:not(:placeholder-shown), .third-screen-input-email:not(:placeholder-shown) {
        border-color: #000000;
    }

    .third-screen-checkbox {
        appearance: none;
        background-color: #fff;
        margin: auto 0 0.7em 0.7em;
        color: currentColor;
        width: 15px;
        height: 15px;
        border: 1px solid #000000;
        border-radius: 4px;
        display: grid;
        place-content: center;
        animation:fadeInUp 1.25s ease forwards;
    }

    .third-screen-checkbox::before {
        content: "";
        width: 9px;
        height: 9px;
        transform: scale(1);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #087E63;
        border-radius: 2px;
    }

    .third-screen-checkbox:checked::before {
        transform: scale(0);
    }

    .third-screen-top-mobile-text{
        opacity: 0;
        position: absolute;
    }

    .third-screen-h {
        margin-top: 2em;
        text-align: right;
        font-weight: 700;
        animation:fadeInUp 1s ease forwards;
    }

    .third-screen-form-text {
        margin-bottom: 1em;
        text-align: right;
        animation:fadeInUp 1s ease forwards;
    }

    #form {
        margin-right: 2em;
    }

    .third-screen-checkbox-label {
        font-size: 14px;
        animation:fadeInUp 1.25s ease forwards;
    }

    .third-screen-skip {
        z-index: 200;
        margin-top: 5.5em;
        opacity: 1;
        cursor: pointer;
        font-weight: 500;
        position: absolute;
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        font-size: 14px;
    }

    .third-screen-progress {
        opacity: 0;
    }

    .third-screen-progress-skip {
        opacity: 0;
    }
}


@media screen and (max-width: 767px) {
    .third-screen {
        z-index: 100;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
    }

    .third-screen.show{
        opacity: 1;
        pointer-events: visible;
    }

    .third-screen-content {
        width: 100%;
        height: 80%;
        background-color: #FFFDFB;
        position: fixed;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        bottom: 0;
    }

    .third-screen-gradient-footer {
        z-index: 150;
        width: 100%;
        height: 33%;
        content:'';
        left: 0;
        top: 68%;
        right: 0;
        bottom: 0;
        position:absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFDFB;
        border-radius: 10px;
    }

    .third-screen-header .third-screen-footer {
        padding: 10px;
    }

    .third-screen-header {
        margin: 3% 0 0 0;
    }

    .third-screen-body {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        text-align:center;
    }
    
    .third-screen-h-mob {
        font-size: 24px;
        font-weight: 700;
        font-family: 'Rubik';
        margin-bottom: 12px;
        margin-top: 40px;
        animation:fadeInUp 1s ease forwards;
        direction: rtl;
    }

    .third-screen-footer {
        padding: 10px;
    }

    .third-screen-container {
        width: 100%;
        opacity: 0;
    }
    
    .emoji {
        font-size: 24px;
        padding: 6px;
    }

    .third-screen-button-upload button {

    }

    .third-screen-first-input, .third-screen-second-input {
        display: flex;
        margin-bottom: 0.5em;
    }

    .third-screen-input-name, .third-screen-input-email {
        background-color: #f7f7f7;
        width: 80%;
        height: 48px;
        margin-left: 10%;
        padding-top: 8px;
        padding-bottom: 8px;
        outline: none;
        line-height: 36px;
        border: 1.5px solid #727272;
        direction: rtl;
        font-size: 14px;
        border-radius: 8px;
        animation:fadeInUp 1.5s ease forwards;
        padding-right: 16px;
    }

    .third-screen-input-name.focus, .third-screen-input-email.focus {
        border-color: #087E63;
    }

    .third-screen-input-name.error, .third-screen-input-email.error {
        border-color: #FF9494;
    }

    .third-screen-input-name:not(:placeholder-shown).error, .third-screen-input-email:not(:placeholder-shown).error {
        border-color: #FF9494;
    }

    .third-screen-icon {
        margin-left: 1em;
        animation:fadeInUp 1.5s ease forwards;
    }

    .third-screen-input-name:focus::placeholder, .third-screen-input-email:focus::placeholder{
        opacity: 0.5;
    }

    .third-screen-input-name::placeholder, .third-screen-input-email::placeholder {
        color: #989898;
        opacity: 1;
        font-weight: 500;
    }

    .third-screen-input-name:not(:placeholder-shown), .third-screen-input-email:not(:placeholder-shown) {
        border-color: #000000;
    }

    .third-screen-checkbox-container{
        display: flex;
        justify-content: end;
        align-items: center;
        margin-right: 0.29em;
        animation:fadeInUp 1.5s ease forwards;
    }

    .third-screen-checkbox {
        appearance: none;
        background-color: #fff;
        margin: auto 0 0.7em 0.7em;
        color: currentColor;
        width: 15px;
        height: 15px;
        border: 1px solid #000000;
        border-radius: 4px;
        display: grid;
        place-content: center;
    }

    .third-screen-checkbox::before {
        content: "";
        width: 9px;
        height: 9px;
        transform: scale(1);
        transition: 120ms transform ease-in-out;
        box-shadow: inset 1em 1em #087E63;
        border-radius: 2px;
    }

    .third-screen-checkbox:checked::before {
        transform: scale(0);
    }

    .third-screen-top-mobile-text{
        opacity: 1;
        display: grid;
        justify-content: center;
        margin-top: 15%;
        font-size: 16px;
    }

    .third-screen-top-mobile-first-text {
        position: absolute;
        left: 40%;
    }

    .third-screen-first-circle, .third-screen-second-circle {
        border-radius: 50%;
        width: 0.5em;
        height: 0.5em;
        margin-left: 0.2em;
        margin-right: 0.2em;
    }

    .third-screen-first-circle {
        animation: fadeInProgress 1.25s forwards;
    }

    .third-screen-second-circle {
        background-color: #CCCCCC;
    }

    .third-screen-line {
        background: #CCCCCC;
        border-radius: 1em;
        width: 1.75em;
        height: 0.5em;
    }

    .third-screen-h {
        font-size: 24px;
        margin-left: 1em;
        margin-top: 2em;
        font-weight: 700;
        text-align: right;
        animation:fadeInUp 1s ease forwards;
        margin-right: 36px;
    }

    .third-screen-form-text {
        margin-right: 36px;
        text-align: right;
        margin-bottom: 1em;
        margin-left: 1em;
        animation:fadeInUp 1.25s ease forwards;
    }

    #form {
        margin-right: 1em;
    }
    
    .third-screen-checkbox-label-mob {
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 10px;

    }
    
    .third-screen-button-upload-mob {
        height: 50px;
        width: 180px;
        border-radius: 8px;
        filter: drop-shadow(0px 0px 4px rgba(216, 216, 216, 0.25));
        background: #087E63;
        border: none;
        margin-top: 24px;
        
        p {
            color: #FFF;
            text-align: center;
            font-family: Rubik;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
        
    }
    
    .onboarding-indicator {
        position: absolute;
        bottom: 10%;
        left: 47%;
        right: 47%;
    }

    .third-screen-skip {
        z-index: 200;
        margin-top: 8em;
        opacity: 1;
        cursor: pointer;
        font-weight: 500;
        position: absolute;
        left: 50%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        font-size: 14px;
    }

    .third-screen-progress-skip {
        opacity: 1;
        display: flex;
        justify-content: flex-start;

        
        p {
            position: absolute;
            bottom: 4%;
            left: 40px;
            font-style: italic;
            font-size: 14px;
        }
    }
    
    .third-screen-progress {
        z-index: 200;
        position: absolute;
        opacity: 1;
        display: flex;
        bottom: 5%;
        left: 50%;
        transform: translate(-50%, 0);
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeInUp {
    0% {
        transform:translate(0px, -10px);
        opacity: 0;
    }
    100% {
        transform:translate(0px, 0);
        opacity: 1;
    }
}

@keyframes easeInTransform {
    0% {
        opacity: 0;
        scale: 0;
    }
    100% {
        opacity: 1;
        scale: 1;
    }
}

@keyframes fadeInProgress {
    0% {
        background-color: #CCCCCC;
    }
    100% {
        background-color: #212121;
    }
}
