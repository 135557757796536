@media screen and (min-width: 767px) {
    .upload-prompt {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
    }

    .upload-prompt.show, .upload-prompt.clicked{
        opacity: 1;
        pointer-events: visible;
        position: absolute;
        z-index: 4;
        animation: changeBackgroundColor 3s ease forwards;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }

    .upload-prompt.clicked {
        animation: reverseChangeBackgroundColor 0.2s linear forwards;
    }

    .upload-prompt-content, .upload-prompt-content.clicked {
        animation: slideFromTop 2.5s ease forwards;
        margin-top: 12px;
        padding: 26px 33px;
        width: 716px;
        height: 90px;
        background-color: #FFFFFF;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row-reverse;
        border-radius: 21px;
        box-shadow: 0 8px 8px 2px rgba(196, 196, 196, 0.15);
    }

    .upload-prompt-content.clicked {
        animation: slideToTop 0.2s ease forwards !important;
    }

    .upload-prompt-content-text {
        display: flex;
        align-items: flex-end;
        flex-direction: column;
    }

    .upload-prompt-content-first-text {
        font-size: 16px;
        font-weight: 700;
    }

    .upload-prompt-content-second-text {
        font-size: 16px;
        font-weight: 400;
        display: flex;
        gap: 10px;
    }

    .upload-prompt-content-second-text p:nth-child(2) {
        color: #898989;
    }

    .upload-prompt-content-second-text p:nth-child(2)::before {
        content: "";
        position: absolute;
        width: 6%;
        height: 1px;
        background-color: #898989;
        top: 63%;
        transform: translateY(-50%) rotate(25deg);
    }

    .upload-prompt-content-button button {
        background-color: #087E63;
        width: 136px;
        height: 35px;
        border-radius: 8px;
        border: none;
    }
    
    .upload-prompt-content-button button:hover {
        background-color: #0b9371;
    }

    .upload-prompt-content-button p {
        text-align: center;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
    }
}

@media screen and (max-width: 767px) {
    .upload-prompt {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
    }

    .upload-prompt.show, .upload-prompt.clicked{
        opacity: 1;
        pointer-events: visible;
        position: absolute;
        z-index: 4;
        animation: changeBackgroundColor 3s ease forwards;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }

    .upload-prompt.clicked {
        animation: reverseChangeBackgroundColor 0.2s linear forwards;
    }

    .upload-prompt-content, .upload-prompt-content.clicked {
        bottom: 15%;
        animation: slideFromRight 2s ease forwards;
        padding: 32px 14px;
        width: 340px !important;
        height: 280px !important;
        background-color: #FFFFFF;
        position: fixed;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        border-radius: 21px;
        box-shadow: 0 0px 8px 3px rgba(196, 196, 196, 0.15);
    }

    .upload-prompt-content.clicked {
        animation: slideToRight 0.2s ease forwards !important;
    }

    .upload-prompt-content-text {
        margin-top: 20px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        margin-bottom: 15px;
    }

    .upload-prompt-content-first-text {
        font-size: 15px;
        font-weight: 700;
    }

    .upload-prompt-content-second-text, .upload-prompt-content-second-text-mobile {
        font-size: 15px;
        font-weight: 400;
        display: flex;
        gap: 10px;
        flex-direction: column-reverse;
        text-align: center;
    }

    .upload-prompt-content-second-text-mobile {
        justify-content: center;
        flex-direction: row;
    }

    .upload-prompt-content-second-text-mobile p:nth-child(2) {
        color: #898989;
    }

    .upload-prompt-content-second-text-mobile p:nth-child(2)::before {
        left: 51%;
        content: "";
        position: absolute;
        width: 14%;
        height: 1px;
        background-color: #898989;
        top: 63%;
        transform: translateY(-50%) rotate(25deg);
    }

    .upload-prompt-content-button button {
        background-color: #087E63;
        width: 136px;
        height: 35px;
        border-radius: 8px;
        border: none;
    }

    .upload-prompt-content-button p {
        text-align: center;
        color: #fff;
        font-weight: 700;
        font-size: 12px;
    }
}

@media screen and (max-height: 680px) {
    .upload-prompt-content, .upload-prompt-content.clicked {
        bottom: 35px;
    }
}

@keyframes slideFromTop {
    0% {
        top: -90px;
    }
    90% {
        top: -50px;
    }
    100% {
        top: 70px;
    }
}

@keyframes slideToTop {
    0% {
        top: 80px;
    }
    100% {
        top: -90px;
    }
}

@keyframes slideFromRight {
    0% {
        right: -500px;
    }
    90% {
        right: -365px;
    }
    95% {
        right: calc((100% - 260px)/2);
    }
    100% {
        right: calc((100% - 340px)/2);
    }
}

@keyframes slideToRight {
    0% {
        right: calc((100% - 310px)/2);
    }
    100% {
        right: -500px;
    }
}

@keyframes changeBackgroundColor {
    0% {
        visibility: hidden;
        background-color: transparent;
    }
    50%{
        visibility: hidden;
        background-color: transparent;
    }
    100% {
        visibility: visible;
        background-color: #ffffff90;
    }
}

@keyframes reverseChangeBackgroundColor {
    0% {
        visibility: visible;
        background-color: #ffffff90;
    }
    100% {
        visibility: hidden;
        background-color: transparent;
    }
}
