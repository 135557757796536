.grayscale {
  filter: grayscale(100%);
}
.preview-image__wrapper {
  padding: 87px 64px;
}

.upload-form {
  top: 150%;
  transition: all 0.3s ease-in-out;
}
.upload-form.show {
  top: 50%;
}
.additional-info-mobile {
  text-align: center;

  font-weight: 700;
  margin-top: 35px;
}
.additional-info-mobile img {
  margin-bottom: 5px;
}
.additional-info-mobile p {
  margin-bottom: 2px;
  font-size: 18px;
  line-height: 21px;
}
.checkout-wrapper {
  padding: 120px 36px 0 36px;
  border-right: 2px solid rgb(196 196 196 / 40%);
}
.aside-checkout {
  text-align: center;
}

.remaining-shipping-text {
  font-size: 14px;
  line-height: 16px;
  color: #0b9371;
}
.aside-checkout p {
  direction: rtl;
  margin-bottom: 15px;
}
.aside-checkout h1 {
  font-size: 24px;
  line-height: 18px;
  font-weight: 400;
  margin-bottom: 9px;
}
.checkout-btn {
  width: 100% !important;
  background-color: #087E63 !important;
  color: white !important;
  border-radius: 8px;
  font-weight: 700 !important;
  filter: drop-shadow(0px 3px 4px rgba(194, 194, 194, 0.26));
  outline: none !important;
}

.freeshippingdesktext {
  font-size: 14px;
  line-height: 16px;
  color: #0b9371;
  margin-right: 10px;
}

@media screen and (max-width: 1400px) and (min-width: 800px) {
  .checkout-btn {
  width: 100% !important;
  font-size: 15px !important;
}

  .checkout-btn:hover {
    background-color: #0b9371!important;
  }
  
  .freeshippingdesk {
    width: 20px;
  }

  .checkout-wrapper {
    padding: 120px 16px 0 16px;
  }
}

.review-images-wrapper {
  display: grid;
  grid-template-columns: 390px 100vw;
  height: calc(100% - 220px);
}

@media only screen and (min-width:767px) {
.review-images-wrapper > div:nth-child(2) {
  background-color: #F3F6F4;
  padding-right: 30vw;
}
}

.gift {
  margin-top: 40px;
}
.gift h2 {
  text-align: right;
  margin-bottom: 22px;
}
ul.gift-list li {
  padding: 0;
  left: unset;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.alert-icon {
  position: absolute;
  width: 20px!important;
  height: 20px!important;
  z-index: 20;
  top: 15px;
  left: 15px;
  cursor: pointer;
}
.album-item-wrapper {
  position: relative;
  height: 260px;
  width: 260px;
  scroll-snap-align: center; /* Ensure items snap to the center */
  flex: none;
}

.album-item-dimension p {
  position: absolute;
  bottom: -40px;
  right: 96px; 
  padding: 6px 10px;
  background-color: #dadada;
  color: white;
  border-radius: 11px;
  font-size: 12px;
  z-index: 1; 
}

.album-item-dimension img {
  position: absolute;
  bottom: -40px;
  left: 0px; 
  z-index: 1; 
  height: 46px;
}

.album-item-shadow {
  position: absolute;
  height: 245px;
  width: 245px;
  background-color: white;
  filter: drop-shadow(-8px 10px 12px rgba(156, 156, 156, 0.35));
  z-index: -1; /* Ensure it's beneath other content */
}

.album-item-wrapper .img-con {
  position: absolute;
  background-image: none!important;
  z-index: 1;
  object-fit: cover;
}

.album-item-wrapper .img-con.ever {
  width: 236px;
  left: 9px;
  top: 9px;
}
.album-item-wrapper .img-con.classic {
  height: 173px;
  width: 170px;
  left: 43px;
  top: 42px;
}

.album-item-wrapper .img-con.bold {
  height: 91%;
  width: 235px;
  left: 10px;
  top: 10px;
}

.album-item-wrapper .img-con.clean {
  width: 183px;
  left: 36px;
  top: 37px;
}

.img-con.edit {
  top: 0 !important;
  right: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
  height: 100% !important;
  width: 100% !important;
}

.my-album .album-item {
  z-index: 2;
}
@media screen and (max-width: 767px) {
  .reviews-info-mobile-cart {
    position: fixed;
    right: 0;
    width: 10%;
    padding: 10px;
    display: flex;
    justify-content: end;
    align-items: center;
    z-index: 99;
    top: 3px;
  }
  .review-infos-mobile-top {
    position: fixed;
    right: 0;
    padding: 10px;
    display: flex;
    z-index: 97;
    
    @media screen and (min-width: 481px) {
      top: 9px;
    }
  }
  
  .svg-container{
    position: relative;
    z-index: 99 !important;
    img {
    height: 26px;
    }
  }
  .overlay-text-10,
  .overlay-text {
    animation: scaleAnimation 0.5s ease-in-out;
  }

  .overlay-text{
    position: absolute;
    top: 30%;
    left: 65%;
    transform: translate(-50%, -50%);
    color: #087E63;
    font-size: 17.3px;
    font-weight: 600;
  }
  
    .overlay-text-10 {
    position: absolute;
    top: 30%;
    left: 70%;
    transform: translate(-50%, -50%);
    color: #087E63;
    font-size: 16px;
    font-weight: 600;
  }
  
  .review-infos-mobile {
    box-shadow: inset 0 4px 6px -1px rgba(0, 0, 0, 0.05), 0 4px 6px -5px rgba(0, 0, 0, 0.05);

    position: fixed;
    top: 49px;
    left: 0;
    width: 100%;
    padding: 10px;
    background-color: #FFFDFB; 
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 97;
    
    @media screen and (min-width: 481px) {
      top: 57px;
    }
  }
  
  .difference {
    font-size: 14px;
    background-color: #0b9371;
    padding: 6px 8px;
    border-radius: 11px;
    color: #fff;
    margin-right: 8px;
    
  }
  
  .price-strikethrough {
    text-decoration: line-through;
    color: #757575; 
    font-size: 14px;
  }
  
  .delivery-date-range {
    font-size: 14px;
  }
 
  .mobile-checkout-btn {
    background-color: #087E63;
    color: white;
    border-radius: 8px;
    font-weight: 700;
    font-family: Rubik;
    width: 90vw;
    margin: auto;
    justify-content: center;
    align-items: center;
    display: flex;
    border: none;
    height: 50px;
 }
 
  .review-images-wrapper {
    grid-template-columns: 1fr;
  }
  .trx {
    overflow: hidden;
    padding: 0px;
    margin: 0px;
  }
  .my-album .classic .img-con {
    top: -3px !important;
    right: -1px !important;
    height: 65%;
    width: 65%;
  }

  .my-album .bold .img-con {
    top: -4px !important;
    right: 0px !important;
    height: 87% !important;
    width: 87% !important;
  }

  .my-album .ever .img-con {
    height: 87% !important;
    width: 87% !important;
    top: -4px !important;
    right: 2px !important;
  }

  .my-album .clean .img-con {
    height: 68% !important;
    width: 68% !important;
    top: -3px !important;
    right: 0px !important;
  }

  .album-item-wrapper .img-con.ever {
    height: 229px;
    width: 231px;
    left: 9px;
    top: 9px;
  }

  .album-item-wrapper .img-con.classic {
    width: 165px;
    left: 41px;
    top: 41px;
  }

  .album-item-wrapper .img-con.bold {
    width: 224px;
    left: 10px;
    top: 10px;
  }

  .album-item-wrapper .img-con.clean {
    width: 175px;
    left: 35px;
    top: 36px;
  }
  .my-album .album-item:not(:last-child) {
    width: 250px;
    height: 250px;
  }
  .album-item-wrapper {
    width: 250px;
    height: 250px;
    scroll-snap-align: center; /* Ensure items snap to the center */
    flex: none;
  }
}

.mobile-progress-container {
  width: 5em;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  position: fixed;
  animation: fadein 1s;
}

.progress-container-text {
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  position: fixed;
  animation: fade 3s linear forwards;
}

.progress-container {
  margin: 5em auto 5em;
  width: 5em;
  text-align: center;
  position: relative;
  animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@keyframes fadeout {
  from { opacity: 1; }
  to   { opacity: 0; }
}

@keyframes fade {
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
}

.progress2 {
  border-radius: 30px;
  background-color: #fff;
}

.progress-bar2 {
  height: 6px;
  border-radius: 30px;
  transition: 0.4s linear;
  transition-property: width, background-color;
}

@keyframes progress50 {
  0% {
    width: 0;
    background: #4f6958;
  }

  100% {
    width: 50%;
    background: #4f6958;
  }
}

@keyframes progress100 {
  0% {
    width: 50%;
    background: #4f6958;
  }

  100% {
    width: 100%;
    background: #4f6958;
  }
}

.progress-moved50 .progress-bar2 {
  background-color: #4F6958;
  width: 50%;
  animation: progress50 2s;
}

.progress-moved100 .progress-bar2 {
  background-color: #4F6958;
  animation: progress100 2s, fadeout 3.5s;
}

.pop-up {
  animation: popUp 0.3s forwards !important;
}

@keyframes popUp {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.01);
  }
  100% {
    transform: scale(1);
  }
}

.disabled {
  pointer-events: none;
  cursor: default;
}

@keyframes scaleAnimation {
  0% {
    transform: translate(-50%, -50%) scale(0.9);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.2);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}