@media screen and (min-width: 767px) {
    .first-screen {
        z-index: 100;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
    }

    .first-screen.show{
        opacity: 1;
        pointer-events: visible;
    }

    .first-screen-content {
        width: 567px;
        height: 455px;
        background-color: #fff;
        position: fixed;
        border-radius: 10px;
    }

    .first-screen-top-text{
        display: flex;
        justify-content: center;
        margin-top: 1%;
        margin-bottom: 3%;
        font-weight: 700;
        font-size: 20px;
        animation:fadeInUp 1s ease forwards;
    }

    .first-screen-gradient-header {
        z-index: 200;
        width: 567px;
        height: 210px;
        content:'';
        left: 0;
        top: 3%;
        right: 0;
        bottom: 0;
        position:absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(90deg, rgb(255, 255, 255, 1) 30%, rgba(255,255,255,0.9) 32%, rgba(255,255,255,0) 50%, rgba(255,255,255,0.9) 68%, rgba(255,255,255,1) 69%);
    }

    .first-screen-video {
        width: 295px;
        height: 200px;
        border-radius: 15px;
        display: block;
        margin: 0 auto;
        padding-top: 20px;
        animation:fadeInUp 1s ease forwards;
    }

    .first-screen-gradient-footer {
        width: 567px;
        height: 100px;
        content:'';
        left: 0;
        top: 78%;
        right: 0;
        bottom: 0;
        position:absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(rgba(255,255,255,0) 0%, rgba(140, 233, 190, 0.25) 100%);
        border-radius: 10px;
    }

    .first-screen-header .first-screen-footer {
        padding: 10px;
    }

    .first-screen-header {
        margin: 3% 0 0 0;
    }

    .first-screen-body {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        text-align:center;
    }

    .first-screen-body-text {
        font-size: 16px;
        animation:fadeInUp 1.25s ease forwards;
    }

    .first-screen-top-text {
        font-weight: 700;
        font-size: 22px;
    }
    .first-screen-footer {
        padding: 10px;
    }

    .first-screen-button-close {
        height: 11%;
        width: 22%;
        margin: 0;
        position: absolute;
        left: 39%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        background-color: #087E63;
        border: none;
        color: white;
        padding: 15px 32px;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        animation:fadeInUp 1.5s ease forwards;
    }

    .first-screen-button-close img {
        padding-right: 4px;
    }
    .first-screen-text-button {
        margin-bottom: 2px;
    }

    .first-screen-progress {
        opacity: 0;
    }
}

@media screen and (max-width: 767px) {
    .first-screen {
        z-index: 100;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        /*transition: all 0.3s ease-in-out;*/
        pointer-events: none;
    }

    .first-screen.show{
        opacity: 1;
        pointer-events: visible;
    }

    .first-screen-content {
        width: 100%;
        height: 80%;
        background-color: #fff;
        position: fixed;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        bottom: 0;
    }

    .first-screen-top-text{
        display: flex;
        justify-content: center;
        margin-top: 10%;
        font-weight: 700;
        margin-bottom: 3%;
        font-size: 20px;
        animation:fadeInUp 1s ease forwards;
    }

    .first-screen-gradient-header {
        z-index: 200;
        width: 100%;
        height: 15.5em;
        content:'';
        left: 0;
        top: 0;
        right: 0;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        position:absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(90deg, rgba(255,255,255,1) 19%, rgba(255,255,255,0.9) 21%, rgba(255,255,255,0) 50%, rgba(255,255,255,0.9) 79%, rgba(255,255,255,1) 80%);
    }

    .first-screen-video {
        width: 15em;
        display: block;
        margin: 0 auto;
        padding-top: 30px;
        animation:fadeInUp 1s ease forwards;
    }

    .first-screen-body-text{
        font-size: 16px;
        animation:fadeInUp 1.25s ease forwards;
    }

    .first-screen-gradient-footer {
        width: 100%;
        height: 25%;
        content:'';
        left: 0;
        right: 0;
        bottom: 0;
        position:absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(rgba(255,255,255,0) 0%, rgba(140, 233, 190, 0.25) 100%);
        border-radius: 10px;
    }

    .first-screen-header .first-screen-footer {
        padding: 10px;
    }

    .first-screen-header {
        margin: 3% 0 0 0;
    }

    .first-screen-body {
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 10px;
        text-align:center;
    }

    .first-screen-footer {
        padding: 10px;
    }

    .first-screen-button-close {
        height: 8%;
        width: 50%;
        margin: 0;
        position: absolute;
        left: 25%;
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        background-color: #087E63;
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        animation:fadeInUp 1.5s ease forwards;
        justify-content: center;
    }

    .first-screen-text-button {
        margin-bottom: 2px;
    }

    .first-screen-progress {
        z-index: 200;
        position: absolute;
        opacity: 1;
        display: flex;
        bottom: 5%;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .first-screen-first-circle, .first-screen-second-circle {
        border-radius: 50%;
        width: 0.5em;
        height: 0.5em;
        margin-left: 0.2em;
        margin-right: 0.2em;
    }

    .first-screen-first-circle {
        background-color: #CCCCCC;
    }

    .first-screen-second-circle {
        animation: fadeInProgress 1.25s forwards;
    }

    .first-screen-line {
        background: #CCCCCC;
        border-radius: 1em;
        width: 1.75em;
        height: 0.5em;
    }

    .first-screen-arrow-left {
        margin-right: 4px;
    }
}

@keyframes fadeInUp {
    0% {
        transform:translate(0px, -10px);
        opacity: 0;
    }
    100% {
        transform:translate(0px, 0);
        opacity: 1;
    }
}

@keyframes fadeInProgress {
    0% {
        background-color: #CCCCCC;
    }
    100% {
        background-color: #212121;
    }
}
