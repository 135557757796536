.reviewsmobile {
    position: relative;
    margin-top: 26px;
    margin-bottom: 56px;
    
    h2 {
    font-weight: 700;    
    text-align: center;
    }
}

.reviews-slider {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 16px auto;
    overflow: hidden;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    border-radius: 11px;
    transition: transform 200ms ease-in-out, opacity 200ms ease-in-out;
  }
  
  .slider-content img {
    width: 100%;
    object-fit: cover;
  }
  
  .review-text {
    text-align: center;
    direction: rtl;
    font-size: 17.3px;
    font-family: 'Rubik';
    padding: 24px 16px 16px;
    /* Additional styling */
  }
  
  .reviewer-info {
    margin-top: 2px;
    text-align: center;
    display: flex;
    flex-direction: column; 
    align-items: center; 
    justify-content: center;
    font-size: 12px;
    margin-bottom: 16px;
    
    img {
        width: 15px;
        margin-bottom: 5px;
    }
  }
  
  .slider-controls button:first-child {
    left: 10px;
  }
  
  .slider-controls button:last-child {
    right: 10px;
  }
  
  .indicator {
    display: flex; 
    justify-content: center;
    position: absolute;
    bottom: -25px; 
    left: 50%;
    transform: translateX(-50%);
    z-index: 20; 
    gap: 4px;
  }
  
  
  .indicator span {
    width: 8px; 
    height: 8px;
    background-color: #eeeeee; 
    border-radius: 50%; 

  }
  
  .indicator span.active {
    background-color: #087E63; 
  }
  