.whatsapp_mobile {
    padding-top: 16px;
    width: 90vw;
    height: calc(50vh);
    background: #FFFFFF;
    box-shadow: 0px 0px 18px 7px rgba(0, 0, 0, 0.15);
    direction: rtl;
    position: relative;
    border-radius: 8px;
    height: 70%;
}

.whatsapp_close {
    position: absolute;
    padding-right: 35px;
    height: 36px;
    margin-top: 12px;
    cursor: pointer;
}

.whatsapp_mobile_header {
    text-align: center;
    line-height: 43px;
    font-size: 36px;
    padding-top: 46px;
    font-weight: 400;
}

.whatsapp_mobile_content {
    font-size: 16px;
    font-weight: 400;
    width: 80vw;
    padding-top: 28px;
    line-height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.whatsapp_mobile_footer {
    margin-top: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.whatsapp_mobile_footer .whatsapp_mobile_link {
    display: flex;
    background: #fff;
    box-shadow: 0px 2px 5px rgba(204, 204, 204, 0.5);
    border-radius: 8px;
    width: 209px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    color: #212121;
    font-size: 16px;
    margin-bottom: 12px;
    justify-content: center;
    align-items: center;
    
}

.whatsapp_mobile_footer .whatsapp_mobile_link img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.qa-whatsapp-modal-mobile {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 8px;
}