.coupon-section-container {
    background-color: #345157;
    display: flex;
    font-weight: 400;
    justify-content: center;
    height: 60px;
    padding-top: 15px;
    width: 100%;
}

.coupon-section-container p {
    color: white;
    direction: rtl;
}

.coupon-item {
    margin: 0px 22px;
}

.coupon-item-title {
    color: #FFFFFF;
    margin-left: 6px;
    vertical-align: middle;
}

.coupon-item-description {
    color: #FFFFFF;
    margin-left: 8px;
    vertical-align: middle;
}

@media only screen and (max-width: 767px)  {
    .coupon-section-container {
        padding-left: 10px;
        padding-top: 12px;
        height: 42px;
    }
    .coupon-item {
        margin: 0 5px;
        font-size: 14px;
    }
    .coupon-item-description {
        color: #FFFFFF;
    }
}

@media only screen and (max-width: 460px)  {
    .coupon-item {
        margin: 0 1px;
    }
}
