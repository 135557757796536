.closebtn {
    border: none;
    outline: none!important;
    background-color: transparent;
    right: 20px;
    top: 20px;
}
.card-and-address-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 19px;
    padding-bottom: 33px;
    border-bottom: 1px solid #727272;
    width: 100%;
}
.checkout-card-wrapper {
    padding: 0 29px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
}

@media screen and (max-width: 768px) {
    .cart_sidebar {
        display: none; 
    }
    .add-address-modal {
        display: none; 
    }    
    
}
      
.card-and-address-buttons button {
    border: none;
    background-color: transparent;
    outline: none!important;
    color: #087E63;
    font-size: 18px;
    line-height: 1;
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: bold;
}
.card-and-address-buttons button.checked {
    color: #000;
}
.checkout-calculation {

}


.checkout-calculation__info {
    color: #212121;
    /* background-color: #fef5f0;
    border-radius: 8px; */
    display: flex;
    gap: 11px;
    align-items: center;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    margin-top: 26px;
    margin-bottom: 56px;
    
    /* @media screen and (min-width: 800px) {
        padding: 16px 16px 16px 16px;
    } */
}


.checkout-calculation__info p {
    margin-bottom: 0;
    margin-right: 6px;
    direction: rtl;
}

.mobile-free-shipping {
    background-color: #f6f6f6;
    padding: 10px 10px 10px 10px;
    border-radius: 8px;
    display: flex;
    color: #727272;
}

.free-shipping-green-text {
    color: #087E63;
}

.mobile-free-shipping img {
    margin-right: 4px;
    margin-left: 6px;
    scale: 1;
    
}
.price__table {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.price__table--row {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;
}

.totalprice-sum {
    color: #212121;
}

.price__table--row.total {
    color: #B12704;
    font-weight: 700;
    font-size: 16px;
    line-height: 1;
    display: flex;
    align-items: center;

}
.price__table--row.total div:nth-child(1) {
    font-size: 24px;
}

.checkout-button-wrapper {
    text-align: center;
    width: 100%;
    padding-top: 40px;
}

.checkout-button-wrapper button {
    width: 85%;
    height: 50px;
    color: #ffd418;
    border: none;
    outline: none!important;
    background-color: #087E63;
    border-radius: 8px;
    font-weight: 700;
    font-size: 15px;
}

.checkout-button-wrapper button:hover {
    background-color: #0b9371;
}
.checkout-button-wrapper button:disabled {
    background-color: #AAAAAA;
    pointer-events: none;
    color: #ffffff;
}
.checkout-button-wrapper p {
    color: #31B3B8;
    font-size: 15px;
    line-height: 17px;
    font-weight: 400;
}
.checkout-wrapper .aside-checkout h1 {
    font-weight: 700;
}
