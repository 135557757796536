@media screen and (min-width: 767px) {
    .click-out {
        z-index: 110;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
    }

    .click-out.show{
        opacity: 1;
        pointer-events: visible;
    }

    .click-out-content {
        width: 395px;
        height: 185px;
        display: grid;
        background-color: #fff;
        position: fixed;
        border-radius: 10px;
        padding: 0 0 40px 40px;
    }

    .click-out-content h5 {
        font-weight: 700;
        margin-right: 40px;
    }
    
    .click-out-header .click-out-footer {
        padding: 10px;
    }

    .click-out-header {
        margin: 8% 0 8px 0;
    }
    
    .click-out-body {
        margin-bottom: 20px;
        margin-right: 40px;
        text-align:right;
    }

    .click-out-footer {
        padding: 10px;
        display: flex;
        height: 35%;
        align-items: center;
    }

    .click-out-text {
        cursor: pointer;
        margin-left: 50%;
        font-size: 14px;
    }

    .click-out-button-close {
        height: 24%;
        width: 35%;
        margin-left: 40px;
        position: absolute;
        left: 4%;
        background-color: #087E63;
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
    }
}


@media screen and (max-width: 767px) {
    .click-out {
        z-index: 110;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
    }

    .click-out.show{
        opacity: 1;
        pointer-events: visible;
    }

    .click-out-content {
        width: 85%;
        height: 37%;
        max-width: 320px;
        max-height: 250px;
        background-color: #fff;
        position: fixed;
        border-radius: 10px;
        display: grid;
    }
    
    .click-out-content h5 {
        font-weight: 700;
    }


    .click-out-header .click-out-footer {
        padding: 10px;
    }

    .click-out-header {
        font-size: 16px;
        padding-right: 2em;
        padding-top: 2em;
    }

    .click-out-body {
        text-align:right;
        font-size: 16px;
        padding-right: 2em;
        padding-bottom: 4em;

    }

    .click-out-footer {
        height: 35%;
        align-items: center;
        padding: 10px;
        display: flex;
    }

    .click-out-text {
        cursor: pointer;
        position: absolute;
        left: 60%;
        bottom: 18%;
        font-size: 14px;
    }

    .click-out-button-close {
        left: 11%;
        bottom: 13%;
        height: 20%;
        width: 40%;
        position: absolute;
        background-color: #087E63;
        border: none;
        color: white;
        text-align: center;
        text-decoration: none;
        font-size: 16px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
    }
}
