.how-to-purchase-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
    width: 100%;
}

.how-to-purchase-title {
    font-size: 36px;
    font-weight: 700;
}

.how-to-purchase-arrows {
    position: flex;
    height: 30px;
    bottom: 1rem;
    
    img:first-child {
        position: relative;
        height: 30px;
        bottom: -60px;
        left: 260px;
    }
    img:nth-child(2) {
        position: relative;
        height: 30px;
        bottom: -210px;
        left: -20px;
        rotate: 180deg;
    }
    img:nth-child(3) {
        position: relative;
        height: 30px;
        bottom: -60px;
        left: -260px;
    }
}

.how-to-purchase-items-container {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 40px;
    justify-content: space-between;
}

.how-to-purchase-item {
    display: flex;
}

.how-to-purchase-item-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-left: 5px;
    margin-right: 15px;
    max-width: 225px;
}


.how-to-purchase-item-column-number {
    display: flex;
    flex-direction: column;
    padding-top: 75px;
    height: 100%;
}

.how-to-purchase-item-title {
    font-size: 24px;
    font-weight: 700;
    margin-top: 10px;
    margin-bottom: 10px;
}


.how-to-purchase-item-description {
    font-size: 14px;
    font-weight: 400;
    direction: rtl;
}

.how-to-purchase-item-number {
        width: 48px;
        height: 48px;
        line-height: 48px;
        border-radius: 50%;
        font-size: 24px;
        color: #fff;
        text-align: center;
        background: #212121;
}


@media only screen and (min-width: 767px) {
    .how-to-purchase-items-container {
        width: 1280px;
    }
}

@media only screen and (max-width: 767px) {

    .how-to-purchase-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-top: 0px;
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 0px;
    }

    .how-to-purchase-title {
        font-size: 24px;
    }

    .how-to-purchase-items-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap-reverse;
        margin-top: -10px;
        justify-content: space-evenly;
    }

    .how-to-purchase-item {
        display: flex; 
        flex: 50%;
        margin-top: 20px;
    }

    .how-to-purchase-item-column {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 0px;
        margin-right: 0px;
        max-width: 225px;
    }


    .how-to-purchase-item-column-number {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-left: 8px;
        padding-top: 35px;
    }

    .how-to-purchase-item-title-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 3px;
    }

    .how-to-purchase-item-title {
        font-size: 16px;
        font-weight: 700;
        margin: 0;
        line-height: 22px;
    }


    .how-to-purchase-item-description {
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        width: 130px;
    }

    .how-to-purchase-item-number {
        border-radius: 50%;
        color: #fff;
        text-align: center;
        background: #000;
        width: 26px;
        height: 26px;
        line-height: 26px;
        border-radius: 50%;
        font-size: 16px;
    }
}