.google-container-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 36px;
    margin-bottom: 36px;
  }
  
  .review-line-mob {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .googlehp-mob {
    width: 2.2em !important;
    height: auto
  }
  
  .starshp-mob {
    width: 6.6em !important; 
  }
  
  .googlehp-mob, .starshp-mob, .googlereviewhp-mob {
    margin: 0 5px; /* Adjust spacing as needed */
  }
  
  .googlereviewhp-mob {
    font-family: 'Rubik', sans-serif;
    font-style: italic;
    font-weight: normal;
    font-size: 15px;
  }
  
  .basedontext-mob {
    font-family: 'Rubik', sans-serif;
    margin-top: 8px;
    font-style: italic;
  }
  