.grayscale {
  filter: grayscale(100%);
}

#edit-modal {
  width: 100%;
  height: 600px;
  background-color: #FFFDFB;
  box-shadow: 0px 0px 15px 5px rgba(33, 33, 33, 0.15);
  border-radius: 10px;
  
  @media screen and (min-width: 767px) {
    max-width: 650px;
  }
}

.edit-modal__header {
  display: flex;
  justify-content: space-between;
  padding: 25px 55px;
  align-items: center;
}

.edit-modal__header button {
  background-color: transparent;
  border: none;
  outline: none !important;
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
}
.edit-modal__header div {
  margin-left: 0.8em;
  margin-top: 0.55em;
}

.edit-modal__header button.edit {
  color: #087E63;
  font-size: 19px;
  line-height: 36px;
  font-weight: 500;
  background-color: #F3F6F4;
  border-radius: 20px;
}

.edit-modal__header button:hover {
  background-color: #F3F6F4;
  
}

.grayscale-filter {
  filter: grayscale(1);
}

.black-white-filter {
  cursor: pointer;
  position: absolute;
  margin-left: 75px;
  margin-top: 15px;
}

.edit-modal__header button.edit:disabled {
  color: #72727249;
  border-radius: 20px;
}
.edit-modal__content {
  text-align: center;
  
  @media screen and (min-width: 767px) {
  margin-top: -26px;
  }
}
.edit-modal__content h2 {
  font-size: 18px;
  line-height: 36px;
  font-weight: 600;
  margin: 0;
}

.close {
  border-radius: 8px;
}

.edit-modal__content p {
  font-size: 14px;
  line-height: 36px;
  margin: 0;
}
.classic.cropper-frame,
.bold.cropper-frame,
.ever.cropper-frame,
.clean.cropper-frame {
  width: 380px;
  height: 380px;
  background-position: center;
  background-size: cover;
  margin: 0 auto;
}
.ever.cropper-frame {
  padding: 13px 22px 24px 13px;
}
.cropper-line,
.cropper-point {
  display: none!important;
}
.cropper-view-box {
  outline: none!important;
}
.bold.cropper-frame {
  padding: 14px 24px 25px 15px;
}
.clean.cropper-frame {
  padding: 52px 60px 62px 53px;
}

.classic.cropper-frame {
  padding: 60px 65px 68px 55px;
  height: 380px;
  background-position: 50% 50%;
  background-size: 380px;
  margin: 0 auto;
}
.even.cropper-frame {
  padding: 13px;
}

.rang_container {
  max-width: 400px;
  margin: 0 auto;
  max-height: 40px;
  background-color: #fff;
  margin-top: 26px;
  box-shadow: 0px 0px 4px 0px rgba(181, 181, 181, 0.15);
  border-radius: 8px;
}

#zoomer {
  width: 80%;
  height: 0px;
  border: 0.2em solid #f2f2f2;
  background-color: #f2f2f2;
  border-radius: 10px;
}

@media screen and (max-width: 767px) {

  .black-white-filter {
    margin-top: -75px;
    justify-content: center;
    cursor: pointer;
    position: relative;
    padding-bottom: 30px;
    padding-right: 80px;
    display: flex;
    align-items: center;
    direction: rtl;
    width: 88vw;
    }
    
    .black-white-filter .text-1 {
    color: #7D7D7D;
    font-weight: 400;
    margin-right: 6px;
    font-size: 14px;
    }
    
    .black-white-filter .text-2 {
    color: #E6166C;
    font-weight: 600;
    margin-right: 8px;
    font-size: 14px;
    }
  
.edit-modal__header-moblow {
  display: flex;
  padding: 25px 55px;
  
  p {
    position: absolute;
    top: -40px;
    font-size: 18px;
    color: white;
    right: 10px;
    direction: rtl;
  }
}
  .classic.cropper-frame {
    padding: 60px 69px 68px 60px;
    height: 380px;
    background-position: 50% 50%;
    background-size: 380px;
    margin: 0 auto;
  }

  .rang_container {
    display: none;
  }
  .cropper-frame {
    transform: scale(0.8);
    -ms-transform: scale(0.8);
    -moz-transform: scale(0.8);
    -webkit-transform: scale(0.8);
    position: relative;
    top: -50px;
  }
  #edit-modal {
    bottom: 0;
    position: absolute;
    border-radius: 10px 10px 0 0;
    height: 550px;
  }

  .edit-modal__header--mobile {
    padding: 15px 15px;
  }

  .edit-modal__header--mobile button {
    border: none;
    background-color: transparent;
    outline: none!important;
    display: inline-flex;
    gap: 10px;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
  }
  .mobile-save-button {
    background: #087E63;
    border-radius: 8px;
    position: absolute;
    bottom: 10px;
    width: 88vw;
    transform: translateX(-50%);
    color: #fff;
    border: none;
    outline: none!important;
    padding: 12px 0;
    filter: drop-shadow(0px 2px 5px rgba(204, 204, 204, 0.5));
  }

  .mobile-save-button div{
    margin-bottom: -4px;
  }
  .modal-title-mob {
    display: flex;
    text-align: center;
    justify-content: center;
    line-height: 24px;
    margin-top: -16px;
    
    img {
      width: 32px;
      margin-right: 6px;
    }
  }
  
  .modal-title-desk {
    display: none;
  }
}

      
@media screen and (min-width:768px) {
  .modal-title-mob {
    display: none;
  }
  
  .modal-title-desk {
    
  }
}

#deskedit-in-frame-loader, #mobiledit-in-frame-loader{
  width: 20px;
  height: 20px;
  border: 2px solid #72727249;
  border-bottom-color: #fff;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin-top: 46%;
  margin-left: 2%
}
