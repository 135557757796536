.with-animation {
    animation: descending-blur 3s  ease forwards;
}

.blurred-loader-image {
    filter: blur(0px);
}

.blurred-loader-image.edit {
    filter: blur(3px);
}

.loader-image {
    object-fit: cover !important;
    left: 47px;
    top: 43px;
}

.with-animation.classic {
    height: 65%;
}

.blurred-loader-image.classic {
    height: 65%;
}

.with-animation.bold {
    height: 89%;
}

.blurred-loader-image.bold {
    height: 89%;
}

.with-animation.ever {
    height: 89%;
}

.blurred-loader-image.ever {
    height: 89%;
}

.with-animation.clean {
    height: 70%;
}

.blurred-loader-image.clean {
    height: 70%;
}

@keyframes descending-blur {
    0% {
        -webkit-filter: blur(10px);
        filter: blur(10px);
        transform: scale(0.9);
    }
    5% {
        transform: scale(1.01);
    }
    10% {
        transform: scale(1);
    }
    100% {
        -webkit-filter: blur(0);
        filter: blur(0);
    }
}

@keyframes upcoming-image {
    0%   {transform: scale(.4);}
  /*   80%   {transform: scale(1.1);} */
    100% {transform: scale(1);}
  }