.image-options {
    background: transparent;
    /* box-shadow: 0px 0px 15px 10px rgba(204, 204, 204, 0.5); */
    border-radius: 8px;
    bottom: 10px;
    position: absolute;
    transition: all .3s ease-in-out;
    /* max-width: calc(100% - 20px); */
    /* left: 10px; */
    width: 94.66%;
}

@media screen and (min-width: 768px) {
  .image-options {
    max-width: calc(100% - 20px);
  }
}

.image-options__item {
    padding: 20px 15px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none!important;
}

.image-options__item:last-child {
  background-color: white;
  border-radius: 8px;
  border: none;
  justify-content: center;
  color: #007aff;
}

.image-options__item:nth-child(2) {
    border-top: 1px solid #dadada;
    background-color: #f4f4f4;
    border-radius: 0px 0px 8px 8px;
    justify-content: end;
    color: #ff3b30;
    
    /* p {
      margin-right: -26px;
    } */
}

.image-options__item:nth-child(1) {
  background-color: white;
  justify-content: end;
  border-radius: 8px 8px 0px 0px;
  color: #007aff;
    
  /* p {
    margin-right: -26px;
  } */
}

.image-options__item img {
    height: 26px;
}