.mobile-checkout {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: 15px;
    background-color: #fff;

}
.mobile-checkout-info {
    margin-top: 15px;
    margin-bottom: 10px;
    text-align: center;
    color: #087E63;
}

.mobile-checkout-info .notice {
    width: 15px;
}

.mobile-checkout-info p {
    margin-bottom: 0;
    font-size: 14px;
    line-height: 1;
    direction:rtl;
}
.mobile-checkout-button {
    width: 100%;
    margin: 0 auto;
}
.mobile-checkout-button a {
    font-weight: 400;
    font-size: 15px;
    line-height: 17px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-checkout-button:active {
    transform: scale(1.02);
}

.mobile-checkout-modal {
    position: fixed;
    background-color: #fff;
    width: 100%;
    border-radius: 10px 10px 0 0;
    
    @media screen and (min-width: 1024px) {
        display: none;
    }
}

.mobile-checkout-modal .van-wrap {
    margin-top: 29px;
    margin-bottom: 10px;
    justify-content: flex-end;
    padding-right: 1rem;
}

.mobile-checkout-modal .van-wrap img {
    margin-right: 0px;
}