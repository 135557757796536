.bitbody {
  background-color: #FAFFFE;
  position: absolute;
  bottom: 1%;
  padding: 12px;
  color: #212121;
  height: 50%;
  width: 90%;
  max-width: 96%;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
}

.bittimer {
  position: absolute; 
  top: 10px;
  left: 10px;
  font-size: 1.1em;
  font-weight: 400;
}

.bitface {
  position: absolute;
  top: 8%;
  right: 5%;
  width: 3.6rem; 
  height: 3.6rem; 
}

.bitinfo {
  position: absolute;
  direction: rtl;
  top: 6%;
  right: 24%;
}

.bitamount {
  font-size: 1.4em;
  font-weight: 600;
  
  p {
    font-size: 0.8em;
    font-weight: 500;
    line-height: 1em;
  }
}
.paragraph {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 1em;
  line-height: 1.5;
  max-width: 90%;
  width: 90%;
  
  img {
    width: 1.5rem; 
    height: 1.5rem; 
    margin-right: 0.3rem;
  }
}

.buttons {
  position: absolute;
  bottom: 1.8rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
 }
 
 .buttons button {
  border-radius: 0.7rem;
  font-size: 1.1em;
  font-weight: 500;
 }
 
 .button1 {
  background-color: #0BD2DE;
  color: #02343B;
  border: none;
  width: 14.8rem;
  height: 3.6rem;
 }
 
 .button2 {
  background-color: #FAFFFE;
  color: #000;
  border: none;
  width: 10rem;
  height: 1.4rem;
  font-weight: 400!important;
  font-size: 0.8rem; 
 }


.spinner {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 2px solid #fff;
    width: 34px;
    height: 34px;
    animation: spin 1s linear infinite;
    margin: 20px auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .fadeInModal {
    animation: fadeInEffect 0.5s, slideUpEffect 0.3s ease;
  }
  
  @keyframes fadeInEffect {
    from { opacity: 0; }
    to { opacity: 1; }
  }
  
  @keyframes moveUp {
    from {
      transform: translateY(8px);
    }
    to {
      transform: translateY(0);
    }
  }
  
  @keyframes slideUpEffect {
    from {
      transform: translate(-50%, 100%);
    }
    to {
      transform: translate(-50%, 0);
    }
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  .modal-closing {
    animation: fadeOutMove 1s ease forwards;
  }
  
  @keyframes fadeOutMove {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    50% {
      opacity: 1;
      transform: translateY(-8px); /* Moves up a bit */
    }
    100% {
      opacity: 0;
      transform: translateY(50px); /* Fades out and moves down a bit */
    }
  }
