@media screen and (min-width: 767px) {
    .address-link {
        z-index: 1005;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
    }

    .address-link.show{
        opacity: 1;
        pointer-events: visible;
    }

    .address-link-content {
        width: 315px;
        height: 185px;
        background-color: #fff;
        position: fixed;
        border-radius: 10px;
        filter: drop-shadow(0 0 0.75rem #00000026);
    }

    .address-link-header .address-link-footer {
        padding: 10px;
    }

    .address-link-header {
        text-align: center;
        padding-top: 30px;
        font-size: 18px;
        line-height: 36px;
        font-weight: 600;
    }

    .address-link-body {
        text-align: center;
        font-size: 14px;
        line-height: 36px;
        font-weight: 400;
    }

    .address-link-footer {
        display: flex;
        height: 35%;
        align-items: center;
        justify-content: center;
    }

    .address-link-text {
        padding-left: 8px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
    }
}


@media screen and (max-width: 767px) {
    .address-link {
        z-index: 1005;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
    }

    .address-link.show{
        opacity: 1;
        pointer-events: visible;
    }

    .address-link-content {
        width: 315px;
        height: 185px;
        background-color: #fff;
        position: fixed;
        border-radius: 10px;
        filter: drop-shadow(0 0 0.75rem #00000026);
    }

    .address-link-header .address-link-footer {
        padding: 10px;
    }

    .address-link-header {
        text-align: center;
        padding-top: 30px;
        font-size: 18px;
        line-height: 36px;
        font-weight: 600;
    }

    .address-link-body {
        text-align: center;
        font-size: 14px;
        line-height: 36px;
        font-weight: 400;
    }

    .address-link-footer {
        display: flex;
        direction: ltr;
        height: 35%;
        align-items: center;
        justify-content: center;
    }

    .address-link-text {
        padding-left: 8px;
        cursor: pointer;
        font-size: 14px;
        font-weight: 700;
    }
}
